import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FileUpload.css';

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [uploadType, setUploadType] = useState('case_data'); 
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN; 
    const [csvErrors, setCSVErrors] = useState([]);
    const [isSendingEmails, setIsSendingEmails] = useState(false);
    
    useEffect(() => {
        fetchAllJobs();
        fetchEmailStatus(); 
        const emailStatusInterval = setInterval(fetchEmailStatus, 2000); 
        return () => clearInterval(emailStatusInterval);
    }, []);

    const fetchAllJobs = async () => {
        try {
            const caseDataResponse = await axios.get(`${axiosDomain}/api/leads/upload-csv/jobs`, {
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('utltoken')}` 
                },
            });
            
            const previousAddressesResponse = await axios.get(`${axiosDomain}/api/leads/upload-previous-addresses/jobs`, {
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('utltoken')}` 
                },
                
            });
                                    
            
            const combinedJobs = [
                ...caseDataResponse.data.jobs,
                ...previousAddressesResponse.data.jobs,
            ];                    
            combinedJobs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));            
            setJobs(combinedJobs);
            if (combinedJobs.length > 0) {
                setSelectedJob(combinedJobs[0]); 
            }
        } catch (error) {
            console.error('Failed to fetch upload jobs:', error);
            alert('Failed to fetch upload jobs.');
        }
    };

    const fetchEmailStatus = async () => {
        try {
            const response = await axios.get(`${axiosDomain}/api/emails/status`, {
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('utltoken')}` 
                },
            });
            setIsSendingEmails(response.data.isRunning);
        } catch (error) {
            console.error('Failed to fetch email status:', error);
        }
    };
    
    useEffect(() => {
        const intervalIds = [];

        jobs.forEach(job => {
            if (job.status === 'processing') {
                const intervalId = setInterval(async () => {
                    try {
                        let response;
                        if (job.job_type === 'case_data') {
                            response = await axios.get(`${axiosDomain}/api/leads/upload-csv/status/${job.id}`, {
                                headers: { 
                                    'Authorization': `Bearer ${localStorage.getItem('utltoken')}` 
                                },
                            });
                        } else if (job.job_type === 'previous_addresses') {
                            response = await axios.get(`${axiosDomain}/api/leads/upload-previous-addresses/status/${job.id}`, {
                                headers: { 
                                    'Authorization': `Bearer ${localStorage.getItem('utltoken')}` 
                                },
                            });
                        }

                        const { status, progress, summary, file_name } = response.data;
                        
                        setJobs(prevJobs => prevJobs.map(j => 
                            j.id === job.id ? { ...j, status, progress, summary, file_name } : j
                        ));
                        
                        if (status === 'completed' || status === 'failed') {
                            clearInterval(intervalId);
                        }

                        if (selectedJob && selectedJob.id === job.id) {
                            setSelectedJob(prevJob => ({ ...prevJob, status, progress, summary, file_name }));
                        }

                    } catch (error) {
                        console.error(`Failed to fetch status for job ${job.id}:`, error);
                        clearInterval(intervalId);
                    }
                }, 2000);
                intervalIds.push(intervalId);
            }
        });
        
        return () => {
            intervalIds.forEach(id => clearInterval(id));
        };
    }, [jobs, axiosDomain, selectedJob]);
    
    const handleFileChange = (e) => setFile(e.target.files[0]);
    const handleUploadTypeChange = (e) => setUploadType(e.target.value);    
    const handleUpload = async () => {
        setCSVErrors([]); 
        if (!file) {
            alert('Please select a file.');
            return;
        }        
        const isAnyJobProcessing = jobs.some(job => job.status === 'processing');
        if (isAnyJobProcessing) {
            alert('An upload is already in progress. Please wait until it finishes.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            let response;
            if (uploadType === 'case_data') {
                response = await axios.post(`${axiosDomain}/api/leads/upload-csv`, formData, {
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem('utltoken')}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else if (uploadType === 'previous_addresses') {
                response = await axios.post(`${axiosDomain}/api/leads/upload-previous-addresses`, formData, {
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem('utltoken')}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            const { jobId } = response.data;
            
            let newJobResponse;
            if (uploadType === 'case_data') {
                newJobResponse = await axios.get(`${axiosDomain}/api/leads/upload-csv/status/${jobId}`, {
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem('utltoken')}` 
                    },
                });
            } else if (uploadType === 'previous_addresses') {
                newJobResponse = await axios.get(`${axiosDomain}/api/leads/upload-previous-addresses/status/${jobId}`, {
                    headers: { 
                        'Authorization': `Bearer ${localStorage.getItem('utltoken')}` 
                    },
                });
            }

            const { status, progress, summary, created_at, updated_at, file_name } = newJobResponse.data;
            
            const newJob = {
                id: jobId,
                status,
                progress,
                summary,
                created_at,
                updated_at,
                file_name,
                job_type: uploadType,
            };
            
            setJobs(prevJobs => [newJob, ...prevJobs]);
            setSelectedJob(newJob);

        } catch (error) {
            console.error('Upload failed:', error);
            setCSVErrors(error.response?.data?.errors || ['Upload failed. Please try again.']);            
        }

        setFile(null); 
    };
    
    const downloadErrorsAsCSV = (errors) => {
        let headers;
        if (selectedJob.job_type === 'case_data') {
            headers = [
                'Email', 'Title', 'Lender', 'Mobile', 'LOA_req', 'POA_req', 'POI_req', 
                'Case ID', 'Batch Num', 'Last Name', 'Telephone', 'Batch Date', 
                'First Name', 'Matter Type', 'Reason Code', 'House No/Name', 
                'Date of Birth', 'Next BO Status', 'Previous Names', 'Current BO Status', 
                'Current Post Code', 'Next BO Sub Status', 'Previous Post Code', 
                'Current Address Line 1', 'Current Address Line 2', 'Current Address Line 3', 
                'Current Address Line 4', 'Credit Agreement Number', 
                'Previous Address Line 1', 'Previous Address Line 2', 
                'Previous Address Line 3', 'Previous Address Line 4', 'Reason'
            ];
        } else if (selectedJob.job_type === 'previous_addresses') {
            headers = [
                'Case ID', 'Previous Names', 'Previous Address Line 1', 'Previous Address Line 2',
                'Previous Address Line 3', 'Previous Address Line 4', 'Previous Post Code', 'Reason'
            ];
        } else {
            headers = ['Reason']; 
        }

        const csvRows = [
            headers.join(','), 
            ...errors.map(error => {
                const row = error.row;
                if (selectedJob.job_type === 'case_data') {
                    return [
                        `"${row.email || ''}"`,
                        `"${row.title || ''}"`,
                        `"${row.lender || ''}"`,
                        `"${row.mobile || ''}"`,
                        `"${row.LOA_req || ''}"`,
                        `"${row.POA_req || ''}"`,
                        `"${row.POI_req || ''}"`,
                        `"${row.case_id || ''}"`,
                        `"${row.batch_num || ''}"`,
                        `"${row.last_name || ''}"`,
                        `"${row.telephone || ''}"`,
                        `"${row.batch_date || ''}"`,
                        `"${row.first_name || ''}"`,                        
                        `"${row.reason_code || ''}"`,
                        `"${row['House No/Name'] || ''}"`,
                        `"${row.date_of_birth || ''}"`,
                        `"${row.next_bo_status || ''}"`,
                        `"${row.previous_names || ''}"`,
                        `"${row.current_bo_status || ''}"`,
                        `"${row.current_post_code || ''}"`,
                        `"${row.next_bo_sub_status || ''}"`,
                        `"${row.previous_post_code || ''}"`,
                        `"${row.current_address_line_1 || ''}"`,
                        `"${row.current_address_line_2 || ''}"`,
                        `"${row.current_address_line_3 || ''}"`,
                        `"${row.current_address_line_4 || ''}"`,
                        `"${row.credit_agreement_number || ''}"`,
                        `"${row.previous_address_line_1 || ''}"`,
                        `"${row.previous_address_line_2 || ''}"`,
                        `"${row.previous_address_line_3 || ''}"`,
                        `"${row.previous_address_line_4 || ''}"`,
                        `"${error.reason || ''}"`,
                    ].join(',');
                } else if (selectedJob.job_type === 'previous_addresses') {
                    return [
                        `"${row.case_id || ''}"`,
                        `"${row.previous_names || ''}"`,
                        `"${row.previous_address_line_1 || ''}"`,
                        `"${row.previous_address_line_2 || ''}"`,
                        `"${row.previous_address_line_3 || ''}"`,
                        `"${row.previous_address_line_4 || ''}"`,
                        `"${row.previous_post_code || ''}"`,
                        `"${error.reason || ''}"`,
                    ].join(',');
                } else {
                    return `"${error.reason || ''}"`;
                }
            })
        ];

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${selectedJob.job_type}_errors.csv`);
        document.body.appendChild(link); 
        link.click();
        document.body.removeChild(link);
    };

    const handleSendEmails = async () => {
        try {
            const response = await axios.get(`${axiosDomain}/api/emails/send-recent-batch`, {
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('utltoken')}` 
                },
            });
            setIsSendingEmails(true);            
        } catch (error) {
            console.error('Failed to send emails:', error);
            alert(error.response?.data?.message || 'Failed to send emails.');
        }
    };

    return (
        <div className="upload-container">            
            <div className="sidebar">
                <h3>Upload Jobs</h3>
                {jobs.length === 0 ? (
                    <p>No upload jobs found.</p>
                ) : (
                    <ul className="jobs-list">
                        {jobs.map(job => (
                            <li 
                                key={job.id} 
                                className={`job-item ${selectedJob && selectedJob.id === job.id ? 'active' : ''}`}
                                onClick={() => setSelectedJob(job)}
                            >
                                <div className="job-basic-info">
                                    <p><strong>ID:</strong> {job.id}</p>
                                    <p><strong>Status:</strong> {job.status.charAt(0).toUpperCase() + job.status.slice(1)}</p>
                                    <p><strong>Type:</strong> {job.job_type === 'case_data' ? 'Case Data' : 'Previous Addresses'}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            
            <div className="main-content">
                <h2>Upload CSV</h2>
                <div className="upload-controls">
                    <div className="upload-type-selector">
                        <label>
                            <input 
                                type="radio" 
                                value="case_data" 
                                checked={uploadType === 'case_data'} 
                                onChange={handleUploadTypeChange}
                            />
                            Case Data
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                value="previous_addresses" 
                                checked={uploadType === 'previous_addresses'} 
                                onChange={handleUploadTypeChange}
                            />
                            Previous Addresses
                        </label>
                    </div>
                    <input 
                        type="file" 
                        accept=".csv" 
                        onChange={handleFileChange} 
                        disabled={jobs.some(job => job.status === 'processing')}
                    />
                    <button 
                        onClick={handleUpload} 
                        disabled={jobs.some(job => job.status === 'processing')}
                    >
                        Upload
                    </button>
                </div>

                {/* New Send Emails Button */}
                <div className="send-emails-section">
                    <button 
                        onClick={handleSendEmails} 
                        disabled={isSendingEmails}
                        className="send-emails-button"
                    >
                        Send Emails
                    </button>
                    {isSendingEmails && (
                        <p className="sending-emails-message">Sending emails...</p>
                    )}
                </div>
                
                {csvErrors.length > 0 && (
                    <div className="csv-errors">
                        <h3>Upload Errors</h3>
                        <ul>
                            {csvErrors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
                
                {selectedJob ? (
                    <div className="job-details">
                        <h3>Job Details</h3>
                        <p><strong>Job ID:</strong> {selectedJob.id}</p>
                        <p><strong>Type:</strong> {selectedJob.job_type === 'case_data' ? 'Case Data' : 'Previous Addresses'}</p>
                        <p><strong>Status:</strong> {selectedJob.status.charAt(0).toUpperCase() + selectedJob.status.slice(1)}</p>
                        <p><strong>Progress:</strong> {selectedJob.progress}%</p>
                        <p><strong>File Name:</strong> {selectedJob.file_name}</p>
                        <progress value={selectedJob.progress} max="100"></progress>
                        
                        {selectedJob.status === 'completed' && selectedJob.summary && (
                            <div className="upload-summary">
                                <h4>Upload Summary</h4>
                                <p><strong>Total Rows:</strong> {selectedJob.summary.totalRows}</p>
                                <p><strong>Successful:</strong> {selectedJob.summary.successful}</p>
                                <p><strong>Duplicates:</strong> {selectedJob.summary.duplicates}</p>
                                <p><strong>Failed:</strong> {selectedJob.summary.failed}</p>
                                {selectedJob.summary.errors.length > 0 && (
                                    <button onClick={() => downloadErrorsAsCSV(selectedJob.summary.errors)}>
                                        Download Errors CSV
                                    </button>
                                )}
                            </div>
                        )}
                        
                        {selectedJob.status === 'failed' && selectedJob.summary && (
                            <div className="upload-summary">
                                <h4>Upload Summary</h4>
                                <p><strong>Total Rows:</strong> {selectedJob.summary.totalRows}</p>
                                <p><strong>Successful:</strong> {selectedJob.summary.successful}</p>
                                <p><strong>Duplicates:</strong> {selectedJob.summary.duplicates}</p>
                                <p><strong>Failed:</strong> {selectedJob.summary.failed}</p>
                                {selectedJob.summary.errors.length > 0 && (
                                    <button onClick={() => downloadErrorsAsCSV(selectedJob.summary.errors)}>
                                        Download Errors CSV
                                    </button>
                                )}
                            </div>
                        )}
                        
                        {selectedJob.status === 'processing' && (
                            <div className="processing-message">
                                <p>Your upload is being processed. Please wait...</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <p>Select a job from the sidebar to view details.</p>
                )}
            </div>
        </div>
    );
};
export default FileUpload;
