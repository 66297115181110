import React from 'react';
import './Maintenance.css';
const Maintenance = () => {
  return (
    <div className="maintenance-div">
      <p className="maintenance-body">This system is under maintenance. Please check back in 24 hours.</p>
      <p className="maintenance-body">Apologies for the inconvenience.</p>
    </div>
  );
};

export default Maintenance;