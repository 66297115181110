import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
    const { logout, limitAccess } = useAuth();
    const navigate = useNavigate();

    // State to toggle mobile menu (overlay panel)
    const [menuOpen, setMenuOpen] = useState(false);

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className="navbar">
            {/* If menuOpen is true, we hide the brand with a conditional class */}
            <div className={`navbar-brand ${menuOpen ? 'hidden-brand' : ''}`}>
                UTL Case Management
            </div>

            {/* Hamburger icon for mobile */}
            <div className="navbar-toggle" onClick={handleToggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>

            {/* Navigation items as a side overlay when open */}
            <div className={`navbar-right ${menuOpen ? 'open' : ''}`}>
                <ul>
                    {limitAccess === false && (
                        <>
                            <li onClick={() => { navigate('/analytics'); setMenuOpen(false); }}>Analytics</li>
                            <li onClick={() => { navigate('/pending-cases'); setMenuOpen(false); }}>Pending Cases</li>
                        </>
                    )}
                    <li onClick={() => { navigate('/to-call'); setMenuOpen(false); }}>Clients to Call</li>
                    <li onClick={() => { navigate('/push-email'); setMenuOpen(false); }}>Resend Email</li>

                    {limitAccess === false && (
                        <>
                            <li onClick={() => { navigate('/reviewed-cases'); setMenuOpen(false); }}>Reviewed Cases</li>
                            <li onClick={() => { navigate('/closed-cases'); setMenuOpen(false); }}>Closed Cases</li>
                            <li onClick={() => { navigate('/rejection-reasons'); setMenuOpen(false); }}>Rejection Reasons</li>
                            <li onClick={() => { navigate('/file-upload'); setMenuOpen(false); }}>File Upload</li>
                            <li onClick={() => { navigate('/pause-follow-ups'); setMenuOpen(false); }}>Pause Follow-ups</li>
                        </>
                    )}
                    <li onClick={logout}>Logout</li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
