// LeadForm.js for PCP Portal

import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './LeadForm.css'; 
import PdfEditor from './PdfEditor';
import Loader from './Loader';

const LeadForm = (userData) => {
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const navigate = useNavigate();
  const sigCanvas = useRef(null);    
  const [triggerPreview, setTriggerPreview] = useState(false);  
  const [formData, setFormData] = useState({
    title: '',
    first_name: '',
    last_name: '',    
    date_of_birth: '',    
    current_address_line_1: '',
    current_address_line_2: '',
    current_address_line_3: '',
    current_address_line_4: '',
    current_post_code: '',
    previous_names: '',    
    car_dealership_name: '',
    vehicle_registration_number: '',
    lender: '',    
    email: '',
    telephone: '',
    mobile: '',    
    documents: [],    
    addlenders: [],
    addcredits: [],
    adddealerships: [],
    addvehicles: [],
    addcases: [],
    ip: '',
    previousadds: [],
    LOA_req: '',
    POI_req: '',
    POA_req: '',
    document_types: [],
    PackOrLOA: '',
    question1: '',
    question2: '',
    question3: '',
  });
  
  // New States for Confirmation and Expiry Dates
  const [confirmedFields, setConfirmedFields] = useState({});
  const [expiryDates, setExpiryDates] = useState({});
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [caseId, setCaseId] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('tk');
  const [pdfBytes, setPdfBytes] = useState(null);
  const [prevAddresses, setPrevAddresses] = useState([]);  
  const [prevAdd, setPrevAdd] = useState({
    previous_address_line_1: '',
    previous_address_line_2: '',
    previous_address_line_3: '',
    previous_address_line_4: '',
    previous_post_code: ''
  });
  const [prevNames, setPrevNames] = useState([]);
  const [prevName, setPrevName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [otherSelected, setOtherSelected] = useState(false);
  const [otherName, setOtherName] = useState(null);
  const [poiSelected, setPOISelected] = useState(null);
  const [poaSelected, setPOASelected] = useState(null);    
  const [fieldErrors, setFieldErrors] = useState({});
  const [uploadErrors, setUploadErrors] = useState([]);
  const [signatureError, setSignatureError] = useState(false);
  const [declarationError, setDeclarationError] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [originalFirstName, setOriginalFirstName] = useState('');
  const [originalLastName, setOriginalLastName] = useState('');
  
  useEffect(() => {
    if (userData && userData.userData) {
      setFormData({
        title: userData.userData.title || '',
        first_name: userData.userData.first_name || '',
        last_name: userData.userData.last_name || '',        
        date_of_birth: userData.userData.date_of_birth || '',        
        current_address_line_1: userData.userData.current_address_line_1 || '',
        current_address_line_2: userData.userData.current_address_line_2 || '',
        current_address_line_3: userData.userData.current_address_line_3 || '',
        current_address_line_4: userData.userData.current_address_line_4 || '',
        current_post_code: userData.userData.current_post_code || '',
        previous_names: userData.userData.previous_names || '',        
        car_dealership_name: userData.userData.car_dealership_name || '',
        vehicle_registration_number: userData.userData.vehicle_registration_number || '',
        lender: userData.userData.lender || '',        
        email: userData.userData.email || '',
        telephone: userData.userData.telephone || '',
        mobile: userData.userData.mobile || '',        
        documents: [],                        
        addlenders: userData.userData.addlenders || [],
        addcredits: userData.userData.addcredits || [],
        adddealerships: userData.userData.adddealerships || [],
        addvehicles: userData.userData.addvehicles || [],
        addcases: userData.userData.addcases || [],
        ip: userData.userData.ip || '',
        previousadds: userData.userData.previousadds || [],
        LOA_req: userData.userData.LOA_req || '',
        POI_req: userData.userData.POI_req || '',
        POA_req: userData.userData.POA_req || '',
        document_types: [],
        PackOrLOA: userData.userData.PackOrLOA || '',
        question1: userData.userData.question1 || '',
        question2: userData.userData.question2 || '',
        question3: userData.userData.question3 || '',
      });
      setOriginalFirstName(userData.userData.first_name || '');
      setOriginalLastName(userData.userData.last_name || '');      

      setCaseId(userData.userData.case_id || '');            
      if (userData.userData.previousadds && userData.userData.previousadds.length > 0) {
        setPrevAddresses(userData.userData.previousadds);
      }             
      if (userData.userData.previous_names && userData.userData.previous_names !== '') {
        setPrevNames(userData.userData.previous_names.split(','));
      }      
    }                    
  }, [userData]);
  
  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handlePrevAddChange = (e) => {
    const { name, value } = e.target;
    setPrevAdd({
      ...prevAdd,
      [name]: value
    });
  };  

  const addPreviousAddress = () => {    
    const existingAdds = prevAddresses;
    const newAdd = {
      previous_address_line_1: prevAdd.previous_address_line_1,
      previous_address_line_2: prevAdd.previous_address_line_2,
      previous_address_line_3: prevAdd.previous_address_line_3,
      previous_address_line_4: prevAdd.previous_address_line_4,
      previous_post_code: prevAdd.previous_post_code
    };
    const newAdds = [...existingAdds, newAdd];
    setPrevAddresses([...prevAddresses, prevAdd]);
    setPrevAdd({
      previous_address_line_1: '',
      previous_address_line_2: '',
      previous_address_line_3: '',
      previous_address_line_4: '',
      previous_post_code: ''
    });
    setFormData({
      ...formData,
      previousadds: newAdds      
    })
  };

  const removePreviousAddress = (index) => {    
    const newAddresses = [...prevAddresses];
    newAddresses.splice(index, 1);
    setPrevAddresses(newAddresses);
    setFormData({
      ...formData,
      previousadds: newAddresses
    });
  };

  const handlePrevNameChange = (e) => {
    setPrevName(e.target.value);
  };

  const addPreviousName = () => {
    const existingNames = prevNames;
    const NewNames = existingNames + ',' + prevName;
    
    setPrevNames([...prevNames, prevName]);
    setFormData({
      ...formData,
      previous_names: NewNames
    });    
    setPrevName('');
  };

  const removePreviousName = (index) => {
    const newNames = [...prevNames];
    newNames.splice(index, 1);
    setPrevNames(newNames);
    setFormData({
      ...formData,
      previous_names: newNames.join(',')
    });
  };
  
  const handleSubmit = async (e) => {        
    e.preventDefault();    

    const checkFields = ['title', 'first_name', 'last_name', 'date_of_birth', 'telephone', 'mobile', 'current_address_line_1',
                          'current_address_line_2', 'current_address_line_3', 'current_address_line_4', 'current_post_code'];

    let newFieldErrors = {};

    // Mandatory field validations
    if (!formData.first_name) {
      setError('Please provide your first name.');
      const errorArea = document.querySelector('.first_name');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      newFieldErrors['first_name'] = 'This field needs confirmation.';
      setFieldErrors(newFieldErrors);
      return;
    }
    if (!formData.last_name) {
      setError('Please provide your last name.');
      const errorArea = document.querySelector('.last_name');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      newFieldErrors['last_name'] = 'This field needs confirmation.';
      setFieldErrors(newFieldErrors);
      return;
    }

    if (!formData.date_of_birth) {
      setError('Please provide your date of birth.');
      const errorArea = document.querySelector('.date_of_birth');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      newFieldErrors['date_of_birth'] = 'This field needs confirmation.';
      setFieldErrors(newFieldErrors); 
      return;
    }
    if (!formData.mobile) {
      setError('Please provide your mobile number.');
      const errorArea = document.querySelector('.mobile');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      newFieldErrors['mobile'] = 'This field needs confirmation.';
      setFieldErrors(newFieldErrors); 
      return;
    }
    if (!formData.current_address_line_1) {
      setError('Please provide your address.');
      const errorArea = document.querySelector('.current_address_line_1');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      newFieldErrors['current_address_line_1'] = 'This field needs confirmation.';
      setFieldErrors(newFieldErrors);
      return;
    }
    if (!formData.current_post_code) {
      setError('Please provide your postcode.');
      const errorArea = document.querySelector('.current_post_code');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      newFieldErrors['current_post_code'] = 'This field needs confirmation.';
      setFieldErrors(newFieldErrors);
      return;
    }

    // Confirmation check for non-empty fields
    checkFields.forEach(field => {            
      if (formData[field] && formData[field] !== '' && !confirmedFields[field]) {          
        newFieldErrors[field] = 'This field needs confirmation.';
      }
    });
    setFieldErrors(newFieldErrors);

    if (Object.keys(newFieldErrors).length > 0) {      
      const firstErrorField = document.querySelector('.highlight');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      setError('Please check the tickbox next to each field to confirm the value.');
      return;
    }

    // Checking if the non-blank fields have been confirmed
    let checkErrors = [];
    checkFields.forEach(field => {            
      if (formData[field] && formData[field] !== '') {        
        if (!confirmedFields[field]) {          
          setError('Please check the tickbox next to each field to confirm the value.');
          checkErrors.push(field);
          return;
        }
      }
    });

    if (formData.PackOrLOA === 'Pack') {
      if (!formData.question1 || !formData.question2 || !formData.question3) {
        setError('Please answer all the mandatory questions.');
        return;
      }
    }

    if (checkErrors.length > 0) {
      return;
    }        

    // Signature validation
    if (sigCanvas.current.isEmpty()) {
      setError('Please provide a signature.');
      setSignatureError(true);
      const errorArea = document.querySelector('.signature-pad');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });      
      return;
    }

    let newUploadErrors = [];
    // Validate POI and POA selections
    if (formData.POI_req === 1 && !poiSelected) {
      setError('Please select a document type for the Proof of Identity document.');
      const errorArea = document.querySelector('.poi-upload');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });      
      if (!newUploadErrors.includes('poi')) {
        newUploadErrors.push('poi');
        setUploadErrors(newUploadErrors);
      }
      return;
    }

    // Validate expiry dates for Passport and Driving Licence
    for (const [index, docType] of formData.document_types.entries()) {
      if (docType === 'Passport' || docType === 'Driving Licence') {
        const expiryDate = expiryDates[index];
        if (!expiryDate && index !== 2) { // Index 2 is reserved for 'Other' document
          setError(`Please provide an expiry date for your ${docType}.`);          
          const errorArea = document.querySelector('.poi-upload');
          errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
          if (!newUploadErrors.includes('expiry')) {
            newUploadErrors.push('expiry');
            setUploadErrors(newUploadErrors);
          }
          return;
        }
        const today = new Date();
        const minExpiryDate = new Date(today.setMonth(today.getMonth() + 1));
        const selectedDate = new Date(expiryDate);
        if (selectedDate < minExpiryDate) {
          setError(`The expiry date for your ${docType} must be at least one month from today.`);
          const errorArea = document.querySelector('.poi-upload');
          errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
          if (!newUploadErrors.includes('expiry')) {
            newUploadErrors.push('expiry');
            setUploadErrors(newUploadErrors);
          }
          return;
        }
      }
    }
              
    if (formData.POA_req === 1 && !poaSelected) {
      setError('Please select a document type for the Proof of Address document.');
      const errorArea = document.querySelector('.poa-upload');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (!newUploadErrors.includes('poa')) {
        newUploadErrors.push('poa');
        setUploadErrors(newUploadErrors);
      }
      return;
    }

    if (nameChanged && !formData.documents[3]) {
      setError('Please upload proof of name change.');
      const errorArea = document.querySelector('.name-change');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      newUploadErrors.push('nameChange');
      setUploadErrors(newUploadErrors);
      return;
    }    
              
    if ((!otherName || otherName === '') && formData.documents[2]) {                  
      setError('Please select a document type for the "Other" document.');
      const errorArea = document.querySelector('.other-upload');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (!newUploadErrors.includes('otherType')) {
        newUploadErrors.push('otherType');
        setUploadErrors(newUploadErrors);
      }
      return;
    }

    if (otherSelected && (otherName  === '' || !otherName) && formData.documents[2]) {
      setError('Please select a document type for the "Other" document.');
      const errorArea = document.querySelector('.other-upload');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (!newUploadErrors.includes('otherType')) {
        newUploadErrors.push('otherType');
        setUploadErrors(newUploadErrors);
      }
      return;
    }

    if (otherName && otherName !== '' && !formData.documents[2]) {     
      setError('Please upload the "Other" document.');
      const errorArea = document.querySelector('.other-upload');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (!newUploadErrors.includes('otherUpload')) {
        newUploadErrors.push('otherUpload');
        setUploadErrors(newUploadErrors);
      }
      return;
    }

    if (!termsAccepted) {
      setError('Declaration box not checked.');
      setDeclarationError(true);
      return;
    }

    for (let i = formData.documents.length - 1; i >= 0; i--) {
      if (!formData.documents[i]) { 
        formData.documents.splice(i, 1);
        formData.document_types.splice(i, 1);
      }
    }

    const incompleteDocTypes = formData.documents.some((doc, idx) => doc && (!formData.document_types[idx] || formData.document_types[idx].trim() === ''));      
    if (incompleteDocTypes) {
      setError('Please specify a document type for all uploaded documents.');
      const errorArea = document.querySelector('.poi-upload');
      errorArea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    setIsLoading(true);
    const data = new FormData();    
    data.append('caseId', caseId);
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && key !== 'documents') {
        if (typeof formData[key] === 'object') {
          data.append(key, JSON.stringify(formData[key]));
        } else {
          data.append(key, formData[key]);     
        }        
      }
    }          

    // Total attachment size validation
    let totalSize = 0;
    formData.documents.forEach((document) => {
        if (document) {
            totalSize += document.size;
        }
    });
    if (pdfBytes) {
      pdfBytes.loaPDFs.forEach((doc) => {
          totalSize += doc.data.length;
      });
      pdfBytes.coaPDFs.forEach((doc) => {
          totalSize += doc.data.length;
      });
      if (pdfBytes.appendixPDFs) {
          pdfBytes.appendixPDFs.forEach((doc) => {
              totalSize += doc.data.length;
          });
      }
    }

    if (totalSize > 25 * 1024 * 1024) { // 25MB in bytes
      setError('The attachments exceed 25MB. Please ensure you upload smaller files / images.');
      setIsLoading(false);
      return;
    }

    formData.documents.forEach((document, index) => {
      if (!document) {
        setError(`Please make sure you've uploaded all the documents. It seems you've selected a document type to upload but haven't selected a file.`);
      }
    });

    // Ensure all uploaded documents have specified types                

    sigCanvas.current.getTrimmedCanvas().toBlob(async (blob) => {
      data.append('signature', blob, 'signature.png');
      
      formData.documents.forEach((document, index) => {                
        const documentType = formData.document_types[index];
        if (document) {                              
          const originalName = document.name;
          const extension = originalName.slice(originalName.lastIndexOf('.'));
          const newFilename = `${documentType}_${index+1}${extension}`;
          data.append('documents', document, newFilename);                    
        }
      });

      if (pdfBytes) {
        pdfBytes.loaPDFs.forEach((doc) => {
          const pdfBlob = new Blob([doc.data], { type: 'application/pdf' });
          if (formData.PackOrLOA === 'LOA') {
            data.append(`LOA`, pdfBlob, `${doc.caseRef}_${formData.last_name}_${doc.lender}_LOA.pdf`);
          } else if (formData.PackOrLOA === 'Pack') {
            data.append(`Pack`, pdfBlob, `${doc.caseRef}_${formData.last_name}_${doc.lender}_Claim Pack.pdf`);
          }
        });
        pdfBytes.coaPDFs.forEach((doc) => {
          const pdfBlob = new Blob([doc.data], { type: 'application/pdf' });
          data.append(`COA`, pdfBlob, `${doc.caseRef}_${formData.last_name}_${doc.lender}_COA.pdf`);
        });
        if (pdfBytes.appendixPDFs) {
          pdfBytes.appendixPDFs.forEach((doc) => {
            const pdfBlob = new Blob([doc.data], { type: 'application/pdf' });
            data.append(`Appendix`, pdfBlob, `Appendix.pdf`);
          });
        }                
      } else {
        setError('The signed documents could not be generated. Please try again.');
        setIsLoading(false);
        return;
      }
                  
      try {                        
        const response = await axios.post(`${axiosDomain}/api/leads/submit?token=${token}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setMessage(response.data.message);
        setIsLoading(false);
        setError('');
        resetForm();
        navigate('/thankyou');        
      } catch (error) {
        if (error.response && error.response.data.includes('Duplicate entry')) {
          setError('Submission failed: Duplicate entry.');
          setIsLoading(false);
        } else {
          setError('Submission failed: ' + error.message);
          setIsLoading(false);
        }
        setMessage('');
        
      }
    }, 'image/png');
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const resetForm = () => {
    setFormData({
      title: '',
      first_name: '',
      last_name: '',      
      date_of_birth: '',      
      current_address_line_1: '',
      current_address_line_2: '',
      current_address_line_3: '',
      current_address_line_4: '',
      current_post_code: '',
      previous_names: '',      
      car_dealership_name: '',
      vehicle_registration_number: '',
      lender: '',      
      email: '',
      telephone: '',
      mobile: '',      
      documents: [],      
      addlenders: [],
      addcredits: [],
      adddealerships: [],
      addvehicles: [],
      addcases: [],
      previousadds: [],
      ip: '',
      LOA_req: '',
      POI_req: '',
      POA_req: '',
      document_types: [],
      PackOrLOA: ''
    });
    clearSignature();
    setTermsAccepted(false);
    setConfirmedFields({});
    setExpiryDates({});
  };

  const setOtherDocumentChange = (e) => {
    const { value } = e.target;    
    if (value === 'Other') {   
      setOtherSelected(true);   
      setOtherName(value);
    } else {      
      setOtherSelected(false);
      setOtherName(value);
    }
  };

  const setOtherNameChange = (e) => {
    const {value} = e.target;
    setOtherName(value);
  }

  const resetSignatureError = () => {
    setSignatureError(false);
    if (error === 'Please provide a signature.') {
      setError('');
    }    
  }

  const handleChange = (e, index, dType) => {    
    const { name, value, files } = e.target;
    if (name === 'documents-poi' || name === 'documents-poa' || name === 'documents-nc' || name === 'documents-other' || name.includes('documents-ca')) {
      if (!dType) {
        setError(`Please select a document type for document ${index + 1}`);
        return;
      }
      if (dType === 'Other' && !otherName.trim()) {
        setError(`Please provide a name for the "Other" document type.`);
        return;
      }
      const newDocuments = [...formData.documents];
      const docTypes = [...formData.document_types];

      while (docTypes.length <= index) {
        docTypes.push(null);
      }

      newDocuments[index] = files[0];
      if (!newDocuments[index]) {
        newDocuments.splice(index, 1);
        docTypes.splice(index, 1);
      } else {
        docTypes[index] = dType;
      }
      
      

      setFormData({
        ...formData,
        documents: newDocuments,        
        document_types: docTypes
      });

      // Reset expiry date if not Passport or Driving Licence
      if (dType !== 'Passport' && dType !== 'Driving Licence') {
        setExpiryDates(prev => {
          const updated = { ...prev };
          delete updated[index];
          return updated;
        });
      }
    } else {
      if (name === 'first_name' || name === 'last_name') {
        if (name === 'first_name') {
          if (value !== originalFirstName) {
            setNameChanged(true);
          } else {
            setNameChanged(false);
          }
        } else if (name === 'last_name') {
          if (value !== originalLastName) {
            setNameChanged(true);
          } else {
            setNameChanged(false);
          }
        }
      }      

      if (name.includes('car_dealership_name')) {        
        const nameIndex = name.replace('car_dealership_name_', '')                
        const dealershipIndex = Number(nameIndex);
        const newDealerships = [...formData.adddealerships];
        newDealerships[dealershipIndex].car_dealership_name = value;
        setFormData({
          ...formData,
          adddealerships: newDealerships
        });
      } else if (name.includes('vehicle_registration_number')) {
        const nameIndex = name.replace('vehicle_registration_number_', '')                
        const vehicleIndex = Number(nameIndex);
        const newVehicles = [...formData.addvehicles];
        newVehicles[vehicleIndex].vehicle_registration_number = value;
        setFormData({
          ...formData,
          addvehicles: newVehicles
        });
      } else if (name.includes('credit_agreement_number')) {        
        const nameIndex = name.replace('credit_agreement_number_', '')                
        const creditIndex = Number(nameIndex);
        const newCredits = [...formData.addcredits];
        newCredits[creditIndex].credit_agreement_number = value;
        setFormData({
          ...formData,
          addcredits: newCredits
        });
      } else {
        setFormData({
          ...formData,
          [name]: value          
        });
      }
    }
  };

  const RenderUploadText = () => {
    if (formData['POI_req'] === 1 || formData['POA_req'] === 1) {              
          return (
            <>
            <div className='upload-div'>
            Further documentation has either been directly requested by your lender or 
              is required to give the lender the best possible chance of tracing your account. 
              <br/><br/>
              Please upload a copy of the documentation specified.
              <br/><br/>
              {formData['POI_req'] === 1 ? (
                <>
                  <div className={`upload-section poi-upload ${uploadErrors.includes('poi') ? 'highlight' : ''}`}>
                  <h3>Upload POI Document<span className="docstar">*</span></h3>
                  <strong>Proof of Identity</strong><br/>
                  (Ideal forms of Proof of Identity are: a copy of your current signed passport or current UK photo card driving licence.)                                  
                  <div key='0' className="form-group form-upload">                  
                    <label>Select Document Type</label>
                    <select name="doc_selection" defaultValue="Select Document Type" onChange={(e) => {
                                                                                                        setPOISelected(e.target.value);
                                                                                                        setUploadErrors(uploadErrors.filter(error => error !== 'poi'));
                                                                                                        }
                                                                                              }
                    >
                      <option value="Select Document Type" disabled>
                              Select Document Type
                      </option>                                            
                      <option value="Passport">Passport</option>
                      <option value="Driving Licence">Driving Licence</option>
                    </select>                  
                    <input
                        type="file"
                        name="documents-poi"
                        onChange={(e) => {
                                          handleChange(e, 0, poiSelected);
                                          setUploadErrors(uploadErrors.filter(error => error !== 'poi'));
                                        }     
                                  }
                        required             
                        disabled={poiSelected === 'Passport' || poiSelected === 'Driving Licence' ? false : true}    
                    /> 
                    {/* Expiry Date Field */}
                    {(poiSelected === 'Passport' || poiSelected === 'Driving Licence') && (
                      <div className={`form-group expiry-date ${uploadErrors.includes('expiry') ? 'highlight' : ''}`}>
                        <label>Expiry Date<span className="docstar">*</span>:</label>
                        <input
                          type="date"
                          name={`expiry_date_${0}`}
                          value={expiryDates[0] || ''}
                          onChange={(e) => {setExpiryDates({ ...expiryDates, [0]: e.target.value });
                                            setUploadErrors(uploadErrors.filter(error => error !== 'expiry'));
                                            }
                                 }
                          required
                          
                        />
                      </div>
                    )}                 
                  </div>   
                  <br/><span className="important-note-poi">*Please ensure that your name on the uploaded document matches the one in our system (on your LOA).</span>
                  <br/><span className="important-note-poi">*Please ensure that the uploaded document has not expired.</span>
                  {error === 'Please select a document type for the Proof of Identity document.' || error.includes('Please provide an expiry date for your') || error.includes('The expiry date for your') 
                    ? (
                      <>
                      <br/>
                      <span className="error-message error-message-box">{error}</span>
                      </>
                     ) : ''
                  }

                </div>                                 
                </>
              ) : ''}        
              <div className="border-div"></div>
              {formData['POA_req'] === 1 ? (
                <>
                <div className={`upload-section poa-upload ${uploadErrors.includes('poa') ? 'highlight' : ''}`}>
                  <h3>Upload POA Document<span className="docstar">*</span></h3>
                  <strong>Proof of Address</strong><br/>
                  Examples of acceptable forms of proof of address are:                  
                  <br/><br/>
                  <ul>
                    <li>A Utility Bill  (dated within the last three months)</li>
                    <li>A Bank Statement  (dated within the last three months)</li>                    
                    <li>A Mortgage Statement (dated within the last 12 months)</li>
                    <li>A Council Tax Bill or Payment Book (dated within the last 12 months)</li>
                </ul>
                  <div key='1' className="form-group">
                    <label>Select Document Type</label>
                      <select name="doc_selection1" defaultValue="Select Document Type" onChange={(e) => {
                                                                                                          setPOASelected(e.target.value);
                                                                                                          setUploadErrors(uploadErrors.filter(error => error !== 'poa'));
                                                                                                          }
                                                                                                  }
                                                                                                  >
                      <option value="Select Document Type" disabled>
                        Select Document Type
                      </option>                                            
                        <option value="Utility Bill">Utility Bill</option>
                        <option value="Bank Statement">Bank Statement</option>                        
                        <option value="Mortgage Statement">Mortgage Statement</option>
                        <option value="Council Tax Bill">Council Tax Bill</option>                    
                      </select>
                      <br/>                    
                    <input
                        type="file"
                        name="documents-poa"
                        onChange={(e) => {
                                          handleChange(e, 1, poaSelected);
                                          setUploadErrors(uploadErrors.filter(error => error !== 'poa'));
                                        } 
                                  }
                        required                 
                        disabled={poaSelected === 'Utility Bill' || poaSelected === 'Bank Statement' || poaSelected === 'Mortgage Statement' || poaSelected === 'Council Tax Bill' ? false : true}                      
                    />                  
                  </div>
                  <br/><span className="important-note-poi">*Please ensure that your name on the uploaded document matches the one in our system (on your LOA).</span>
                  <br/><span className="important-note-poi">*Please ensure that the uploaded document has not expired.</span>
                  <br/><span className="important-note-poi">*The address on the document should be the same as the one in our system (on your LOA).</span>
                  {error === 'Please select a document type for the Proof of Address document.' &&  
                    (
                      <>
                      <br/>
                      <span className="error-message error-message-box">{error}</span>
                      </>
                    )                     
                  }               
                </div>     
                </>
              ) : ''}           
              <div className="border-div"></div>
              {/* Section to show if name is changed */}
              {nameChanged && (
                <div className={`upload-section name-change ${uploadErrors.includes('nameChange') ? 'highlight' : ''}`}>                
                  <h3>Upload Proof of Name Change</h3>
                  <span className="important-note">You have updated your name. The updated one doesn't match our records. Please provide a proof of name change<br/><br/></span>                                    
                  Please upload proof of name change documentation that may be beneficial in assisting your lender to trace your account(s), for example a marriage certificate.                                    
                  <div key='3' className="form-group">                                        
                    <input
                        type="file"
                        name="documents-nc"
                        onChange={(e) => {
                                          handleChange(e, 3, 'Proof of Name Change');
                                          setUploadErrors(uploadErrors.filter(error => error !== 'nameChange'));
                                        }
                                  }                                                         
                    />                                                                       
                  </div>      
                  <br/><br/>   
                  {error === 'Please upload proof of name change.' &&  
                    (
                      <>
                      <br/>
                      <span className="error-message error-message-box">{error}</span>
                      </>
                    )                     
                  }                 
                </div>
              )}
              <div className="border-div"></div>
              <div className={`upload-section other-upload ${uploadErrors.includes('otherUpload') ? 'highlight' : ''}`}>                
                <h3>Upload Other Document</h3>
                <span className="important-note">This is the most valuable information that can be provided to us, so we ask that you please take the time to have a thorough search of any records you may have.<br/><br/></span>
                For the optional additional documents that may provide evidence of account:
                <br/><br/>
                Please upload here any other documentation that may be beneficial in assisting your
                lender to trace your account(s), for example a letter / communication from your 
                lender which details an account number.  
                <div key='2' className="form-group">
                  <br/><br/>
                  <label>Select Other Document Type</label>
                  <select className={`${uploadErrors.includes('otherType') ? 'highlight' : ''} `} name="doc_selection1" defaultValue="Select Document Type" onChange={(e) => {
                                                                                                                                                                              setOtherDocumentChange(e);
                                                                                                                                                                              setUploadErrors(uploadErrors.filter(error => error !== 'otherType'));                 
                                                                                                                                                                            }
                                                                                                                                                                          }
                    >
                    <option value="Select Document Type" disabled>
                      Select Document Type
                    </option>                                            
                    <option value="Passport">Passport</option>
                    <option value="Driving Licence">Driving Licence</option>
                    <option value="Utility Bill">Utility Bill</option>
                    <option value="Bank Statement">Bank Statement</option>
                    <option value="Credit Card Statement">Credit Card Statement</option>
                    <option value="Mortgage Statement">Mortgage Statement</option>
                    <option value="Council Tax Bill">Council Tax Bill</option>                    
                    <option value="Other">Other</option>                    
                  </select>
                  {otherSelected ? (
                    <input type="text" name="other_name" value={otherName} onChange={(e) => {
                                                                                              setOtherNameChange(e);
                                                                                              setUploadErrors(uploadErrors.filter(error => error !== 'otherType'));
                                                                                            }
                                                                                          }
                    />
                  ) : ''}                      
                  <input
                      type="file"
                      name="documents-other"
                      onChange={(e) => handleChange(e, 2, otherName)}                                                         
                      disabled={!otherName || otherName === '' ? true : false}
                  />             
                  
                </div>      
                <br/><br/>
                {error === 'Please select a document type for the "Other" document.' || error === 'Please upload the "Other" document.'
                    ? (
                      <>
                      <br/>
                      <span className="error-message error-message-box">{error}</span>
                      </>
                     ) : ''
                  }
                If you are unsure of what documentation you should upload please contact our Customer
                Service Team by email on assistance@claimlionlaw.com or by calling (0)800 599 9993.
              </div>
            </div>
          </>
        );
      } else {
        return (<></>);    
      }
    }

    const handleFieldConfirmation = (fieldName) => {
      setConfirmedFields({
        ...confirmedFields,
        [fieldName]: true
      });
      // Remove field error if exists
      setFieldErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };                              
        delete updatedErrors[fieldName];
        if (
          error === 'Please check the tickbox next to each field to confirm the value.' &&
          Object.keys(updatedErrors).length === 0
        ) {
          setError('');
        }
        return updatedErrors;
      });
    };

    const RenderFieldWithConfirmation = (label, name, type = "text") => {
      const hasValue = formData[name] && formData[name] !== '';
      const isConfirmed = confirmedFields[name];
      const fieldError = fieldErrors[name];
      return (
        <div className="form-group">
          <label>{label}:</label>
          <input
            type={type}
            name={name}
            value={formData[name]}
            onChange={(e) => {
              handleChange(e);            
              setConfirmedFields({
                ...confirmedFields,
                [name]: false
              });            
              setFieldErrors({
                ...fieldErrors,
                [name]: ''
              });
              
            }}
            className={`${hasValue && !isConfirmed ? 'highlight' : ''} ${isConfirmed ? 'confirmed-input' : ''} ${fieldError ? 'checkerror' : ''} ${name}`}                    
          />
          {hasValue && (
            <button
              type="button"
              className={`confirm-button ${isConfirmed ? 'confirmed' : ''}`}
              onClick={() => {
                              handleFieldConfirmation(name)
                            } 
                      }
            >
              {'✓'}
            </button>
          )}
          {fieldError && (
            <>
            <span data-tip data-for={`error-tooltip-${name}`} className="error-icon">⚠️</span>
            
            </>
          )}
        </div>
      );
    };
    
    return (    
      <>
      {isLoading ? (
        <>        
          <Loader />          
        </>
        ) : (
        <>      
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
                <div className="form-row">
                  <div className="form-section row1col1">
                    <label>Email:</label>
                    <p>{formData.email}</p>              
                    <br/>
                    <label>Lender(s):</label>                  
                    {formData.addlenders.map((lender, index) => (
                      <p key={index}>{lender.lender}</p>
                    ))}
                  </div>
                  <div className="form-section row1col2">              
                    <p className="header-note">
                      Please provide as much detail as possible in the fields on this screen.<br/><br/>
                      If any detail is incorrect please type over the current information and add any detail that is missing. <br/><br/>
                      The detail you enter is used by lenders to trace your accounts/records so they can release to us the detail about your PCP agreement(s) we need to progress your claim. <br/><br/>
                      Lenders use previous addresses and previous names to trace records so please add all the detail you can. <br/><br/>
                      An account number (for each lender if applicable)  would be extremely beneficial.  
                    </p>                  
                  </div>
                </div>
            </div>
            <div className="form-row">                
                <div className="user-details form-section">                    
                    <h3>User Details <span className="text-with-heading">(Please click all the checkboxes to confirm the information)</span></h3>   
                                                         
                    {RenderFieldWithConfirmation('Title', 'title')}
                    {RenderFieldWithConfirmation('First Name', 'first_name')}
                    {RenderFieldWithConfirmation('Last Name', 'last_name')}
                    {RenderFieldWithConfirmation('Date of Birth', 'date_of_birth', 'date')}
                    {RenderFieldWithConfirmation('Telephone', 'telephone')}
                    {RenderFieldWithConfirmation('Mobile', 'mobile')}
                </div>

                <div className="address-details form-section">
                    <h3>Address Details</h3>
                    {RenderFieldWithConfirmation('Current Address Line 1', 'current_address_line_1')}
                    {RenderFieldWithConfirmation('Current Address Line 2', 'current_address_line_2')}
                    {RenderFieldWithConfirmation('Current Address Line 3', 'current_address_line_3')}
                    {RenderFieldWithConfirmation('Current Address Line 4', 'current_address_line_4')}
                    {RenderFieldWithConfirmation('Current Post Code', 'current_post_code')}
                </div>
                                                                     
            </div>
            <div className="form-row">                
              <div className="prev-address-details form-section">
                  <h3>Previous Address Details</h3>
                  <span className="title-text">If you have more than one previous address, please add them to the list.</span>
                  <br/><br/>

                  <div className="form-group">
                      <label>Previous Address Line 1:</label>
                      <input type="text" name="previous_address_line_1" value={prevAdd.previous_address_line_1} onChange={handlePrevAddChange} />
                  </div>
                  <div className="form-group">
                      <label>Previous Address Line 2:</label>
                      <input type="text" name="previous_address_line_2" value={prevAdd.previous_address_line_2} onChange={handlePrevAddChange} />
                  </div>
                  <div className="form-group">
                      <label>Previous Address Line 3:</label>
                      <input type="text" name="previous_address_line_3" value={prevAdd.previous_address_line_3} onChange={handlePrevAddChange} />
                  </div>
                  <div className="form-group">
                      <label>Previous Address Line 4:</label>
                      <input type="text" name="previous_address_line_4" value={prevAdd.previous_address_line_4} onChange={handlePrevAddChange} />
                  </div>
                  <div className="form-group">
                      <label>Previous Post Code:</label>
                      <input type="text" name="previous_post_code" value={prevAdd.previous_post_code} onChange={handlePrevAddChange} />
                  </div>                    
                  <button type="button" className="paddbutton" onClick={() => addPreviousAddress()}>Add Previous Address</button>                
              </div>                                            
              <div className="prev-address-table form-section">
                <h3>Previous Addresses</h3>
                <table className="prev-add-table">
                  <thead>
                    <tr>
                      <th className="padd1">#</th>
                      <th className="padd2">Line 1</th>
                      <th className="padd3">Line 2</th>
                      <th className="padd4">Line 3</th>
                      <th className="padd5">Line 4</th>
                      <th className="padd6">Post Code</th>
                      <th className="padd7">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prevAddresses.map((address, index) => (
                      <tr key={index}>
                        <td className="padd1">{index + 1}</td>
                        <td className="padd2">{address.previous_address_line_1}</td>
                        <td className="padd3">{address.previous_address_line_2}</td>
                        <td className="padd4">{address.previous_address_line_3}</td>
                        <td className="padd5">{address.previous_address_line_4}</td>
                        <td className="padd6">{address.previous_post_code}</td>
                        <td className="padd7">
                          <a href="#!" onClick={() => removePreviousAddress(index)}>Delete</a>                        
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>  
                <div className="previous-names">
                  <h3>Previous Names</h3>
                    <div className="form-group pnamegroup">
                      <div className="pnameinput">
                        <label>Add a name:</label>
                        <input type="text" value={prevName} onChange={handlePrevNameChange} />                    
                        <div className="pnamebutton">
                          <button type="button" onClick={addPreviousName}>Add Previous Name</button>
                        </div>
                      </div>
                      
                    </div>
                    <div className="prev-names-table">
                      <table className="prev-name-table">
                        <thead>
                          <tr>
                            <th className="padd1">#</th>
                            <th className="padd2">Previous Name</th>
                            <th className="padd3">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {prevNames.map((name, index) => (
                            <tr key={index}>
                              <td className="padd1">{index + 1}</td>
                              <td className="padd2">{name}</td>
                              <td className="padd3">
                                <a href="#!" onClick={() => removePreviousName(index)}>Delete</a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>   
                  </div>            
              </div>
              
            </div>

            <div className="form-row case-form-row">            
              <div className="case-form-section">
                <h3>Case Related Details</h3>
                <span className="important-note title-text">
                  Please note this is your lender’s agreement number, NOT our reference beginning with CA.
                  <br/><br/>
                </span>                                                                                                                                
                {formData.addvehicles.map((vehicle, index) => (                                              
                  <React.Fragment key={index}>
                    <div className="case-lender-info">
                      <p className="lender-case-header">
                        Case Details against {formData.addlenders[index].lender}
                      </p>                          
                      
                      {/* Left Section */}
                      <div className="left-section">
                        <div className="case-form-group vehicle-details">
                          <label htmlFor={`car_dealership_name_${index}`}>Car Dealership Name:</label>
                          <input 
                            type="text"                             
                            name={`car_dealership_name_${index}`}
                            value={formData.adddealerships[index].car_dealership_name} 
                            onChange={handleChange} 
                          />
                        </div>
                        <div className="case-form-group vehicle-details">
                          <label htmlFor={`vehicle_registration_number_${index}`}>Vehicle Registration Number:</label>
                          <input 
                            type="text"                             
                            name={`vehicle_registration_number_${index}`}
                            value={vehicle.vehicle_registration_number} 
                            onChange={handleChange} 
                          />
                        </div>
                      </div>
                      
                      {/* Right Section */}
                      <div className="right-section">
                        <div className="case-form-group vehicle-details">
                          <label htmlFor={`credit_agreement_number_${index}`}>Credit Agreement Number:</label>
                          <input 
                            type="text"                             
                            name={`credit_agreement_number_${index}`}
                            value={formData.addcredits[index].credit_agreement_number || ''} 
                            onChange={handleChange} 
                          />
                        </div>
                        <div className="credit-upload vehicle-details">
                          <label htmlFor={`upload_credit_agreement_${index}`}>Upload Credit Agreement:</label>
                          <input
                            type="file"                            
                            name={`documents-ca-${index}`}
                            onChange={(e) => handleChange(e, index + 4, "Credit Agreement")}                                                                                         
                          />                                     
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>    
            </div>      
              
                                 
            <div className="form-row">  


              {/* New Mandatory Questions Section */}
              {formData.PackOrLOA === 'Pack' && (
                <div className="questions-section form-section">
                  <h3>Please answer the following questions:</h3>
                  <ol>
                    <li>
                      Did the car dealership(s) tell you about the commission they received for arranging the finance to purchase your vehicle?
                      <div className="form-group questions">
                        <input 
                          type="radio" 
                          id="q1_yes" 
                          name="question1" 
                          value="yes" 
                          onChange={handleChange} 
                          required
                          className="optioncheck" 
                        />
                        <label htmlFor="q1_yes" className="optionlabel">Yes</label>

                        <input 
                          type="radio" 
                          id="q1_no" 
                          name="question1" 
                          value="no" 
                          onChange={handleChange} 
                          required 
                        />
                        <label htmlFor="q1_no">No</label>
                      </div>
                    </li>
                    <li>
                      Have you already submitted a PCP claim for your vehicle(s)?
                      <div className="form-group questions">
                        <input 
                          type="radio" 
                          id="q2_yes" 
                          name="question2" 
                          value="yes" 
                          onChange={handleChange} 
                          required 
                        />
                        <label htmlFor="q2_yes">Yes</label>

                        <input 
                          type="radio" 
                          id="q2_no" 
                          name="question2" 
                          value="no" 
                          onChange={handleChange} 
                          required 
                        />
                        <label htmlFor="q2_no">No</label>
                      </div>
                    </li>
                    <li>
                      Are you currently in an IVA, bankruptcy or other debt management arrangement?
                      <div className="form-group questions">
                        <input 
                          type="radio" 
                          id="q3_yes" 
                          name="question3" 
                          value="yes" 
                          onChange={handleChange} 
                          required 
                        />
                        <label htmlFor="q3_yes">Yes</label>

                        <input 
                          type="radio" 
                          id="q3_no" 
                          name="question3" 
                          value="no" 
                          onChange={handleChange} 
                          required 
                        />
                        <label htmlFor="q3_no">No</label>
                      </div>
                    </li>
                  </ol>
                </div>
                )}
              {/* End of Questions Section */}


            </div>    

          <div className="form-row signaturerow"> 
              <div className="signature-details form-section">
              <div className="form-group">                                    
                  <span className="important-note">
                    *If you are required to upload a Proof of Identity, please make sure your signature matches the signature on your uploaded document. 
                    {formData.PackOrLOA === 'Pack' && (
                      <div className="sample-pack">                        
                          By entering your signature you are agreeing to be bound by the terms and conditions set out in our retainer documentation which you can view by clicking this <a className="sample-link" href="/sample_pack.pdf" target="_blank" rel="noreferrer">link</a>                        
                      </div>                             
                    )}                   

                  </span>
                  <label>Signature:</label>
                  <div className={`signature-pad ${signatureError ? 'highlight' : ''}`}>
                    <SignatureCanvas ref={sigCanvas} canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }} onBegin={() => resetSignatureError()} />
                  </div>
                  <span className="important-note">*Please sign in the box above.</span><br/>
                  <button type="button" onClick={clearSignature}>Clear Signature</button>
              </div>
              </div>      
              <div className="additional-details form-section">                  
                  {RenderUploadText()}                    
              </div>  
              <div className="border-div"></div>
          </div>
          
          <div className="pdf-embed form-section">          
            <PdfEditor 
                formData={formData}               
                signatureData={sigCanvas.current && !sigCanvas.current.isEmpty() ? sigCanvas.current.getCanvas().toDataURL('image/png') : null}
                onPdfGenerated={setPdfBytes} 
                triggerPreview={triggerPreview}
                PreviousAddresses={prevAddresses}
                PreviousNames={prevNames}
                PackOrLOA={formData.PackOrLOA}

            />
          </div>                

            <div className={`form-group checkbox-group ${declarationError ? 'highlight' : ''}`}>
              <input type="checkbox" checked={termsAccepted} onChange={handleCheckboxChange} />
              <label>I declare that all information provided herein is true, complete, and correct to the best of my knowledge and belief.</label>
            </div>
            <button type="submit" className="submit-button">Submit</button>
            {message && <p className="message success">{message}</p>}
            {error && <p className="message-error">{error}</p>}
            {error && Object.keys(fieldErrors).length > 0 ? 
              (
                <>
                <div className="errorexample">                
                  <span className="errexampletext">Example:</span>
                  <img src="/confirmfield.png" alt="Error" className="errexampleimg"/> 
                </div>
                </>
              ) : ''
            }
          </form>
        </div>      
      </>
      )}  
      </>
    );
  };
  
  export default LeadForm;
