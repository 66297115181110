import React, { useState } from 'react';
import axios from 'axios';
import './PauseFollowUps.css';

const PauseFollowUps = () => {
    const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
    const [searchString, setSearchString] = useState('');
    const [error, setError] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePause = async () => {
        setError('');
        setStatusMessage('');
        
        if (!searchString.trim()) {
            setError('Please enter an email or case ID.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(
                `${axiosDomain}/api/pause-followups`,
                { searchString },
                { headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` } }
            );
            setStatusMessage(response.data.message);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError('An error occurred while pausing follow-ups. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pause-container">
            <div className="pause-form">
                <div className="pause-header">
                    <h2>Pause Follow Ups</h2>
                </div>

                <div className="form-group-pause">
                    <label htmlFor="searchString">Enter Email or Case ID</label>
                    <input
                        type="text"
                        id="searchString"
                        name="searchString"
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        placeholder="Email or Case ID"
                    />
                </div>

                <button
                    className="pause-button"
                    onClick={handlePause}
                    disabled={loading}
                >
                    {loading ? 'Pausing...' : 'Pause Follow Ups'}
                </button>

                {error && <p className="error-message">{error}</p>}
                {statusMessage && <p className="status-message">{statusMessage}</p>}
            </div>
        </div>
    );
};

export default PauseFollowUps;
