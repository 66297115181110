// PdfEditor.js for PCP Portal

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';

const PdfEditor = ({ formData, signatureData, onPdfGenerated, triggerPreview, PreviousAddresses, PreviousNames, PackOrLOA}) => {
    const [pdfBytes, setPdfBytes] = useState(null);
    const [coaBytes, setCOABytes] = useState(null);
    const [appendixBytes, setAppendixBytes] = useState(null);
    //const [dataUri, setDataUri] = useState('');
    const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;


    useEffect(() => {
        const fetchPdfTemplate = async () => {
            try {
                if (!pdfBytes) {
                    if (PackOrLOA === 'Pack') {
                        const response = await axios.get(`${axiosDomain}/api/pdf/pack`, {
                            responseType: 'arraybuffer'
                        });
                        if (pdfBytes === null) {
                            setPdfBytes(response.data);
                        }
                    } else if (PackOrLOA === 'LOA') {
                        const response = await axios.get(`${axiosDomain}/api/pdf/loa`, {
                        responseType: 'arraybuffer'
                        });
                        if (pdfBytes === null) {
                            setPdfBytes(response.data);
                        }                    
                    } 
                }
            } catch (error) {
                console.error('Error fetching PDF template:', error);
            }
        };

        const fetchCOATemplate = async () => {
            if (coaBytes === null) {
                try {
                    const response = await axios.get(`${axiosDomain}/api/pdf/coa`, {
                    responseType: 'arraybuffer'
                    });                
                    setCOABytes(response.data);
                } catch (error) {
                    console.error('Error fetching COA template:', error);
                }
            }
        };

        const fetchAppendixTemplate = async () => {
            if (!appendixBytes) {
                try {
                    const response = await axios.get(`${axiosDomain}/api/pdf/appendix`, {
                    responseType: 'arraybuffer'
                    });
                    setAppendixBytes(response.data);
                } catch (error) {
                    console.error('Error fetching Appendix template:', error);
                }
            }
        };
  
        fetchPdfTemplate();
        fetchCOATemplate();
        fetchAppendixTemplate();

    }, [PackOrLOA]);
  
  useEffect(() => {
    const fillPdf = async () => {
      if (!pdfBytes) {        
        return;
      }

      //const mergedDoc = await PDFDocument.create();
      let pdfDocs = [];

      function buildAddressLines(line1, line2, line3, line4, postCode) {                
        const lines = [
        line1,
        line2,
        line3,
        line4,
        postCode
        ];              
        const filtered = lines.filter((l) => l && l.trim() !== '');              
        return filtered;
        }    

      if (formData.addlenders) {        
        for (const [index, lender] of formData.addlenders.entries()) {
            pdfDocs.push({
                lender: lender.lender,                                
                caseref: formData.addcases[index],                
                data: await PDFDocument.load(pdfBytes)
                }             
            );
            const pdfDoc = pdfDocs[pdfDocs.length - 1].data;            
            const form = pdfDoc.getForm();   
            const currentAddress = buildAddressLines(formData.current_address_line_1 || '', formData.current_address_line_2 || '', formData.current_address_line_3 || '', formData.current_address_line_4 || '', formData.current_post_code || '');
            const previousAddress = buildAddressLines(PreviousAddresses[0]?.previous_address_line_1 || '', PreviousAddresses[0]?.previous_address_line_2 || '', PreviousAddresses[0]?.previous_address_line_3 || '', PreviousAddresses[0]?.previous_address_line_4 || '', PreviousAddresses[0]?.previous_post_code || '');

            if (PackOrLOA === 'LOA') {
                // Update form fields specific to PCP
                form.getTextField('LenderName1').setText(lender.lender);  
                form.getTextField('DealerName1').setText(formData.adddealerships[index].car_dealership_name);  
                form.getTextField('AccountHolder1').setText(formData.title + ' ' + formData.first_name + ' ' + formData.last_name);                        
                form.getTextField('DOB1').setText(new Date(formData.date_of_birth).toLocaleDateString('en-GB'));                               
                form.getTextField('AddLine1_2_1').setText(currentAddress[0] || '');
                form.getTextField('AddLine2_2_1').setText(currentAddress[1] || '');
                form.getTextField('AddLine3_2_1').setText(currentAddress[2] || '');
                form.getTextField('AddLine4_2_1').setText(currentAddress[3] || '');
                form.getTextField('PostCode_2_1_1').setText(currentAddress[4] || '');
                form.getTextField('OurRef_1').setText(formData.addcases[index] || '');
                if (PreviousAddresses.length > 0) {                    
                    form.getTextField('PrevAddLine1_1').setText(previousAddress[0] || '');
                    form.getTextField('PrevAddLine2_1').setText(previousAddress[1] || '');
                    form.getTextField('PrevAddLine3_1').setText(previousAddress[2] || '');
                    form.getTextField('PrevAddLine4_1').setText(previousAddress[3] || '');
                    form.getTextField('PrevPostCode_1').setText(previousAddress[4] || '');
                }
                if (PreviousNames.length > 0) {
                    const previousNameField = form.getField('PreviousName') || null;
                    if (previousNameField) {
                        previousNameField.setText(PreviousNames[0] || '');
                    }
                }
                form.getTextField('LOADate1').setText(new Date().toLocaleDateString('en-GB'));
                if (signatureData) {
                    const pngImage = await pdfDoc.embedPng(signatureData);
                    const pages = pdfDoc.getPages();
                    const firstPage = pages[0];
                    firstPage.drawImage(pngImage, {
                    x: 90,
                    y: 290,
                    width: 100,
                    height: 50
                    });
                }
            } else if (PackOrLOA === 'Pack') {
                form.getTextField('Salutation').setText(formData.title + ' ' + formData.first_name + ' ' + formData.last_name);
                form.getTextField('AddLine1').setText(currentAddress[0] || '');
                form.getTextField('AddLine2').setText(currentAddress[1] || '');
                form.getTextField('AddLine3').setText(currentAddress[2] || '');
                form.getTextField('AddLine4').setText(currentAddress[3] || '');
                form.getTextField('PostCode').setText(currentAddress[4] || '');                
                form.getTextField('Personalized').setText(formData.title + ' ' + formData.first_name + ' ' + formData.last_name);
                form.getTextField('ThisDate').setText(new Date().toLocaleDateString('en-GB'));
                form.getTextField('YourRef').setText(formData.addcases[index] || '');
                form.getTextField('PPIName').setText(formData.first_name + ' ' + formData.last_name);
                form.getTextField('PPICA').setText(formData.addcases[index] || '');
                form.getTextField('Salutation_1').setText(formData.title + ' ' + formData.first_name + ' ' + formData.last_name);
                form.getTextField('AddLine1_1').setText(currentAddress[0] || '');
                form.getTextField('AddLine2_1').setText(currentAddress[1] || '');
                form.getTextField('AddLine3_1').setText(currentAddress[2] || '');
                form.getTextField('AddLine4_1').setText(currentAddress[3] || '');
                form.getTextField('PostCode_1').setText(currentAddress[4] || '');
                form.getTextField('YourRef_1').setText(formData.addcases[index] || '');
                form.getTextField('ThisDate_1').setText(new Date().toLocaleDateString('en-GB'));
                form.getTextField('FullName').setText(formData.first_name + ' ' + formData.last_name);                            
                if (formData.question1 === 'yes') {
                    form.getRadioGroup('Ques1').select('Q1Yes');
                } else {
                    form.getRadioGroup('Ques1').select('Q1No');
                }
                
                if (formData.question2 === 'yes') {
                    form.getRadioGroup('Ques2').select('Q2Yes');
                } else {
                    form.getRadioGroup('Ques2').select('Q2No');
                }
                
                if (formData.question3 === 'yes') {
                    form.getRadioGroup('Ques3').select('Q3Yes');
                } else {
                    form.getRadioGroup('Ques3').select('Q3No');
                }

                form.getTextField('QDate').setText(new Date().toLocaleDateString('en-GB'));                
                form.getTextField('LenderName1').setText(lender.lender);    
                //form.getTextField('LenderName2').setText(lender.lender);    
                form.getTextField('DealerName1').setText(formData.adddealerships[index].car_dealership_name);
                //form.getTextField('DealerName2').setText(formData.adddealerships[index]);

                form.getTextField('AccountHolder1').setText(formData.first_name + ' ' + formData.last_name);                                    
                //form.getTextField('AccountHolder2').setText(formData.first_name + ' ' + formData.last_name);                                    

                form.getTextField('DOB1').setText(new Date(formData.date_of_birth).toLocaleDateString('en-GB'));              
                //form.getTextField('DOB2').setText(new Date(formData.date_of_birth).toLocaleDateString('en-GB'));              

                form.getTextField('AddLine1_2_1').setText(currentAddress[0] || '');
                //form.getTextField('AddLine1_2_2').setText(currentAddress[0] || '');

                form.getTextField('AddLine2_2_1').setText(currentAddress[1] || '');
                //form.getTextField('AddLine2_2_2').setText(currentAddress[1] || '');

                form.getTextField('AddLine3_2_1').setText(currentAddress[2] || '');
                //form.getTextField('AddLine3_2_2').setText(currentAddress[2] || '');

                form.getTextField('AddLine4_2_1').setText(currentAddress[3] || '');
                //form.getTextField('AddLine4_2_2').setText(currentAddress[3] || '');

                form.getTextField('PostCode_2_1_1').setText(currentAddress[4] || '');
                //form.getTextField('PostCode_2_2').setText(currentAddress[4] || '');

                form.getTextField('LOADate1').setText(new Date().toLocaleDateString('en-GB'));
                //form.getTextField('LOADate2').setText(new Date().toLocaleDateString('en-GB'));

                form.getTextField('OurRef_1').setText(formData.addcases[index] || '');
                //form.getTextField('OurRef_2').setText(formData.addcases[index] || '');

                if (PreviousAddresses.length > 0) {
                    form.getTextField('PrevAddLine1_1').setText(previousAddress[0] || '');
                    //form.getTextField('PrevAddLine1_2').setText(previousAddress[0] || '');

                    form.getTextField('PrevAddLine2_1').setText(previousAddress[1] || '');
                    //form.getTextField('PrevAddLine2_2').setText(previousAddress[1] || '');

                    form.getTextField('PrevAddLine3_1').setText(previousAddress[2] || '');
                    //form.getTextField('PrevAddLine3_2').setText(previousAddress[2] || '');

                    form.getTextField('PrevAddLine4_1').setText(previousAddress[3] || '');
                    //form.getTextField('PrevAddLine4_2').setText(previousAddress[3] || '');

                    form.getTextField('PrevPostCode_1').setText(previousAddress[4] || '');
                    //form.getTextField('PrevPostCode_2').setText(previousAddress[4] || '');
                }

                form.getTextField('ThisDate_2').setText(new Date().toLocaleDateString('en-GB'));
                form.getTextField('Party').setText(formData.first_name + ' ' + formData.last_name + ', of ' + currentAddress.join(', '));                
                form.getTextField('Defendant').setText(lender.lender);
                form.getTextField('YourName').setText(formData.first_name + ' ' + formData.last_name);
                form.getTextField('DBADate').setText(new Date().toLocaleDateString('en-GB'));
                form.getTextField('CaseReference_1').setText(formData.addcases[index] || '');
                form.getTextField('CaseReference').setText(formData.addcases[index] || '');

                
                if (signatureData) {
                    const pngImage = await pdfDoc.embedPng(signatureData);
                    const pages = pdfDoc.getPages();
                    let sigPage = pages[3];
                    sigPage.drawImage(pngImage, {
                    x: 90,
                    y: 145,
                    width: 100,
                    height: 50
                    });

                    sigPage = pages[4];
                    sigPage.drawImage(pngImage, {
                    x: 90,
                    y: 290,
                    width: 100,
                    height: 50
                    });
                    
                    sigPage = pages[5];
                    sigPage.drawImage(pngImage, {
                    x: 90,
                    y: 290,
                    width: 100,
                    height: 50
                    });

                    sigPage = pages[10];
                    sigPage.drawImage(pngImage, {
                    x: 370,
                    y: 355,
                    width: 100,
                    height: 50
                    });                
                }
            }

            const pdfBytesUpdated = await pdfDoc.save();
            //const donorPDF = await PDFDocument.load(pdfBytesUpdated);
            //const copiedPages = await mergedDoc.copyPages(donorPDF, donorPDF.getPageIndices());
            //copiedPages.forEach(page => mergedDoc.addPage(page));            
            pdfDocs[pdfDocs.length - 1].data = pdfBytesUpdated;            
        }      
        
        let COADocs = [];
        
        if (coaBytes) {
            for (const [index, lender] of formData.addlenders.entries()) {
                COADocs.push({
                    lender: lender.lender,
                    caseref: formData.addcases[index],
                    data: await PDFDocument.load(coaBytes)
                    }             
                );
                
                
                const COADoc = COADocs[COADocs.length - 1].data;                
                const COAform = COADoc.getForm(); 
                COAform.getTextField('datecreated').setText(new Date().toLocaleDateString('en-GB'));
                COAform.getTextField('clientfullname').setText(formData.title + ' ' + formData.first_name + ' ' + formData.last_name);
                COAform.getTextField('docsigneddate').setText(new Date().toLocaleDateString('en-GB'));                                        
                COAform.getTextField('refnumber').setText(formData.addcases[index] || '');
                COAform.getTextField('ipaddress').setText(formData.ip || '');                

                if (signatureData) {
                    const pngImage = await COADoc.embedPng(signatureData);
                    const pages = COADoc.getPages();
                    const firstPage = pages[0];
                    firstPage.drawImage(pngImage, {
                    x: 220,
                    y: 450,
                    width: 100,
                    height: 50
                    });
                }
                const coaBytesUpdated = await COADoc.save();
                //const COAPDF = await PDFDocument.load(coaBytesUpdated);
                //const copiedPagesCOA = await mergedDoc.copyPages(COAPDF, COAPDF.getPageIndices());
                //copiedPagesCOA.forEach(page => mergedDoc.addPage(page));
                COADocs[COADocs.length - 1].data = coaBytesUpdated;            
            }
        }
        
        let appendixDocs = [];
        if (appendixBytes) {
            if (PreviousNames.length > 1 || PreviousAddresses.length > 1) {
                appendixDocs.push({
                    data: await PDFDocument.load(appendixBytes)
                    }
                );
                                
                const appendixDoc = await PDFDocument.load(appendixBytes);
                const appendixForm = appendixDoc.getForm();
                PreviousNames.forEach((name, index) => {
                    if (index + 1 <= 3) {
                        appendixForm.getTextField(`PrevName${index+1}`).setText(name || '');
                    }
                });

                PreviousAddresses.forEach((address, index) => {
                    if (index + 1 <= 5) {
                        const pAddress = buildAddressLines(address.previous_address_line_1 || '', address.previous_address_line_2 || '', address.previous_address_line_3 || '', address.previous_address_line_4 || '', address.previous_post_code || '');

                        appendixForm.getTextField(`PrevAdd${index+1}Line1`).setText(pAddress[0] || '');
                        appendixForm.getTextField(`PrevAdd${index+1}Line2`).setText(pAddress[1] || '');
                        appendixForm.getTextField(`PrevAdd${index+1}Line3`).setText(pAddress[2] || '');
                        appendixForm.getTextField(`PrevAdd${index+1}Line4`).setText(pAddress[3] || '');
                        appendixForm.getTextField(`PrevAdd${index+1}Line5`).setText(pAddress[4] || '');
                    }
                });
                if (signatureData) {
                    const pngImage = await appendixDoc.embedPng(signatureData);
                    const pages = appendixDoc.getPages();
                    const firstPage = pages[0];
                    firstPage.drawImage(pngImage, {
                    x: 90,
                    y: 90,
                    width: 100,
                    height: 50
                    });
                }
                appendixForm.getTextField('LOADate').setText(new Date().toLocaleDateString('en-GB'));
                const appendixBytesUpdated = await appendixDoc.save();
                //const appendixPDF = await PDFDocument.load(appendixBytesUpdated);
                //const copiedPagesAppendix = await mergedDoc.copyPages(appendixPDF, appendixPDF.getPageIndices());
                //copiedPagesAppendix.forEach(page => mergedDoc.addPage(page));    
                appendixDocs[appendixDocs.length - 1].data = appendixBytesUpdated;        
            }
        }
        
        //const mergedPdfBytes = await mergedDoc.save();                       
        onPdfGenerated({
            loaPDFs: pdfDocs.map(pdf => ({
                lender: pdf.lender,
                caseRef: pdf.caseref,
                data: pdf.data
            })),
            coaPDFs: COADocs.map(pdf => ({
                lender: pdf.lender,
                caseRef: pdf.caseref,
                data: pdf.data
            })),
            appendixPDFs: appendixDocs.length > 0 ? appendixDocs.map(doc => ({                
                data: doc.data
            })) : []
            }
        );

        //const newUri = await mergedDoc.saveAsBase64({ dataUri: true });
        //setDataUri(newUri);        
      }            

    };
    if (pdfBytes && (triggerPreview || formData || signatureData)) {
        fillPdf();
    }        

  }, [pdfBytes, coaBytes, appendixBytes, formData, signatureData, onPdfGenerated, triggerPreview]);   
  
    return null; // Remove iframe or any visual component since it's handled elsewhere
};

export default PdfEditor;
