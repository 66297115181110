import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './pendingCases.css';
import ProcessedDocuments from './ProcessedDocuments'; 
import PDFViewer from '../PDFViewer'; 

const PendingCases = () => {
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const [cases, setCases] = useState([]);
  const [allCases, setAllCases] = useState([]);
  const [currentCaseIndex, setCurrentCaseIndex] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [fileContents, setFileContents] = useState([]); // To store case files content
  const [lockError, setLockError] = useState('');
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [allLenders, setAllLenders] = useState([]);
  const [allCaseRefs, setAllCaseRefs] = useState([]);
  const [selectedLender, setSelectedLender] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isCasesLoading, setIsCasesLoading] = useState(true);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [error, setError] = useState(null); // New state for handling errors
  const [toCall, setToCall] = useState(false); // New state for handling API calls
  const [showCall, setShowCall] = useState(false); // New state for showing API calls 

  // State variables for modal
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState(''); // 'unsavedProgress', 'missingRejectionReason'
  const [nextAction, setNextAction] = useState(null); // 'next', 'previous'

  // State for completion
  const [isComplete, setIsComplete] = useState(false);

  const fetchPendingCases = async () => {
    try {
      setIsCasesLoading(true);
      const response = await axios.get(`${axiosDomain}/api/pending-cases`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
      });      
      const allCasesData = response.data.pendingCases;
      allCasesData.sort((a, b) => new Date(a.uploaded_at) - new Date(b.uploaded_at)); // Sort by oldest submission      
      const lenderNames = allCasesData.map((c) => c.lenders).flat();
      const uniqueLenders = [...new Set(lenderNames)];
      setAllLenders(uniqueLenders);

      const caseRefs = allCasesData.map((c) => c.case_ids).flat();
      const uniqueCaseRefs = [...new Set(caseRefs)];
      setAllCaseRefs(uniqueCaseRefs);

      setAllCases(allCasesData);
      setCases(allCasesData);        
      setIsCasesLoading(false);
    } catch (err) {
      console.error("Error fetching pending cases:", err);
      setError('Failed to load pending cases. Please try again later.');
      setIsCasesLoading(false);
    }
  };
  useEffect(() => {    
    fetchPendingCases();
  }, []);

  // Function to apply filters based on selectedLender and searchValue
  const applyFilters = () => {
    let filteredCases = allCases;
    setSelectedFiles({});
    if (selectedLender) {
      filteredCases = filteredCases.filter(c => c.lenders.includes(selectedLender));
    }

    if (searchValue) {
      filteredCases = filteredCases.filter(c => c.case_ids.includes(searchValue));
    }

    setCases(filteredCases);    
    setCurrentCaseIndex(0);
  };

  // Function to fetch case details and lock the case
  const fetchCaseDetails = async (caseId) => {
    try {
      setIsDocumentsLoading(true);
      const response = await axios.post(`${axiosDomain}/api/case-details`, { case_id: caseId }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
      });
      setFileContents(response.data.documents);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        console.error("Case is locked by another user");
        setLockError('Case is being looked upon by another user');
      } else {
        console.error("Error fetching case details:", err);
        setError('Failed to load case details. Please try again later.');
      }
    } finally {
      setIsDocumentsLoading(false);
    }
  };

  // Fetch case details for the current case when it's opened
  useEffect(() => {
    if (cases.length > 0) {
      const caseId = cases[currentCaseIndex].case_ids[0];
      setLockError('');
      setFileContents([]);
      fetchCaseDetails(caseId);
    }
  }, [currentCaseIndex, cases]);

  // Fetch rejection reasons
  useEffect(() => {
    const fetchRejectionReasons = async () => {
      try {
        const response = await axios.get(`${axiosDomain}/api/rejection-reasons`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
        });
        setRejectionReasons(response.data.reasons);
      } catch (err) {
        console.error("Error fetching rejection reasons:", err);
        setError('Failed to load rejection reasons. Please try again later.');
      }
    };

    fetchRejectionReasons();
  }, [axiosDomain]);

  // Unlock case on component unmount
  useEffect(() => {
    return () => {
      unlockCase(cases[currentCaseIndex]?.case_ids[0]);
    };
  }, [currentCaseIndex, cases]);

  // Function to update case details by calling the API
  const updateCaseDetails = async () => {
    setIsDocumentsLoading(true);
    const caseId = cases[currentCaseIndex].case_ids[0];
    try {
      await axios.post(`${axiosDomain}/api/update-case-details`, {
        case_ids: cases[currentCaseIndex].case_ids,
        selectedFiles: selectedFiles,
        callClient: toCall        
      }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
      });
      return true;
    } catch (err) {
      console.error("Error updating case details:", err);
      setError(err?.response?.data?.message || 'Failed to update case details. Please try again.');
    } finally {
      setIsDocumentsLoading(false);
    }
    return false;
  };


  const forceUnlockCase = async (caseId) => {
    try {
      await axios.post(`${axiosDomain}/api/force-unlock-case`, { case_id: caseId }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
      });
      setLockError('');
      fetchCaseDetails(caseId);
    } catch (err) {
      console.error("Error unlocking the case:", err);
      setError('Failed to unlock the case. Please try again later.');
    }
  };

  const checkCaseLockStatus = async (caseId) => {
    try {
      const response = await axios.get(`${axiosDomain}/api/case-lock-status`, 
              { params: { case_id: caseId },
                headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
              });
      if (response.data.isLocked) {
        setLockError('Case is being looked upon by another user');
        return true;
      }
    } catch (err) {
      console.error("Error checking case lock status:", err);
      setError('Failed to check case lock status. Please try again later.');
    }    
  };

  useEffect(() => {
    const interval = setInterval(() => {      
      try {
        checkCaseLockStatus(cases[currentCaseIndex].case_ids[0]);
      } catch (err) {
        console.error("Error checking case lock status:", err);
        return() => clearInterval(interval);
      }
    }, 15000); 
    return () => clearInterval(interval);
  }, [currentCaseIndex, cases]);
  


  // Modified handleNext function
  const handleNext = async () => {
    const reviewedDocs = Object.keys(selectedFiles);
    const totalDocs = fileContents.map(doc => doc.id);    

    if (reviewedDocs.length > 0 && reviewedDocs.length < totalDocs.length) {
      // If some documents are reviewed and others are not
      setModalMessage('If you continue, your progress on the current case will be lost. Would you like to proceed to the next case?');
      setModalType('unsavedProgress');
      setNextAction('next');
      setShowModal(true);
      return;
    }        

    if (reviewedDocs.length === totalDocs.length && reviewedDocs.length > 0) {
      // All documents have been reviewed

      const rejectedDocs = reviewedDocs.filter(docId => selectedFiles[docId].document_status === 'rejected');
      const docsMissingReason = rejectedDocs.filter(docId => {
        const reason = selectedFiles[docId].rejection_reason;
        const isOther = reason === 'Other';
        const otherReason = selectedFiles[docId].other_reason;
        return !reason || (isOther && !otherReason);
      });

      if (docsMissingReason.length > 0) {
        // Prompt user to provide rejection reasons
        setModalMessage('Some rejected documents are missing rejection reasons. Please provide a reason for each rejected document. If you choose "Yes" to continue, your progress on this case will not be saved. Would you like to continue to the next case?');
        setModalType('missingRejectionReason');
        setNextAction('next');
        setShowModal(true);
        return;
      }

      const discardedDocs = reviewedDocs.filter(docId => selectedFiles[docId].document_status === 'discarded');
      if (discardedDocs.length === totalDocs.length) {
        setModalMessage('You have discarded all the documents submitted by the client. This progress would not be saved. Please accept or reject at least one document such that the case could either move forward or the client could be contacted regarding resubmission of required documents.');
        setModalType('allDiscarded');        
        setShowModal(true);
        return;
      }

      // All documents reviewed and have reasons; proceed to update case details
      const caseUpdated = await updateCaseDetails();

      if (caseUpdated) {
        fetchPendingCases();
        const caseId = cases[currentCaseIndex].case_ids[0];
        const newAllCases = allCases.filter(c => c.case_ids[0] !== caseId);
        setAllCases(newAllCases);
        const newCases = cases.filter(c => c.case_ids[0] !== caseId);
        setCases(newCases);
        setSelectedFiles({});
        setShowCall(false);
        setToCall(false);
        if (currentCaseIndex < newCases.length) {
          setCurrentCaseIndex(currentCaseIndex);
        } else {
          setCurrentCaseIndex(0);
        }        
      } else {
        console.log('Case not updated');
        console.log('case updated:', caseUpdated);
      }

      return;
    }

    // If no documents have been reviewed, proceed to next case
    setSelectedFiles({});

    // New Logic
    await unlockCase();

    if (currentCaseIndex < cases.length - 1) {
      setCurrentCaseIndex(currentCaseIndex + 1);
    } else {
      // Last case, handle completion
      setIsComplete(true);
    }
  };

  

  // Modified handlePrevious function
  const handlePrevious = async () => {
    const reviewedDocs = Object.keys(selectedFiles);
    const totalDocs = fileContents.map(doc => doc.id);

    if (reviewedDocs.length > 0 && reviewedDocs.length < totalDocs.length) {
      // If some documents are reviewed and others are not
      setModalMessage('If you continue, your progress on the current case will be lost. Would you like to proceed to the previous case?');
      setModalType('unsavedProgress');
      setNextAction('previous');
      setShowModal(true);
      return;
    }

    if (reviewedDocs.length === totalDocs.length && reviewedDocs.length > 0) {
      // All documents have been reviewed
      const rejectedDocs = reviewedDocs.filter(docId => selectedFiles[docId].document_status === 'rejected');
      const docsMissingReason = rejectedDocs.filter(docId => {
        const reason = selectedFiles[docId].rejection_reason;
        const isOther = reason === 'Other';
        const otherReason = selectedFiles[docId].other_reason;
        return !reason || (isOther && !otherReason);
      });
      
      if (docsMissingReason.length > 0) {
        // Prompt user to provide rejection reasons
        setModalMessage('Some rejected documents are missing rejection reasons. Please provide a reason for each rejected document. If you choose "Yes" to continue, your progress on this case will not be saved. Would you like to continue to the previous case?');
        setModalType('missingRejectionReason');
        setNextAction('previous');
        setShowModal(true);
        return;
      }

      const discardedDocs = reviewedDocs.filter(docId => selectedFiles[docId].document_status === 'discarded');
      if (discardedDocs.length === totalDocs.length) {
        setModalMessage('You have discarded all the documents submitted by the client. This progress would not be saved. Please accept or reject at least one document such that the case could either move forward or the client could be contacted regarding resubmission of required documents.');
        setModalType('allDiscarded');        
        setShowModal(true);
        return;
      }

      // All documents reviewed and have reasons; proceed to update case details
      const caseUpdated = await updateCaseDetails();
      if (caseUpdated) {
        fetchPendingCases();
        // Remove the current case from the list
        const caseId = cases[currentCaseIndex].case_ids[0];
        const newAllCases = allCases.filter(c => c.case_ids[0] !== caseId);
        setAllCases(newAllCases);
        const newCases = cases.filter(c => c.case_ids[0] !== caseId);
        setCases(newCases);
        setSelectedFiles({});
        setShowCall(false);
        setToCall(false);
        if (currentCaseIndex > 0) {        
          setCurrentCaseIndex(currentCaseIndex - 1);
        } else {
          setCurrentCaseIndex(0);
        }
      }

      return;
    }

    // If no documents have been reviewed, proceed to previous case
    setSelectedFiles({});

    // New Logic
    await unlockCase();
    if (currentCaseIndex > 0) {
      setCurrentCaseIndex(currentCaseIndex - 1);
    }
  };

  // Function to handle status change for a document
  const handleStatusChange = (docID, status, reason = '') => {
    const isRejected = status === 'rejected';
    const sFiles = { ...selectedFiles };
    const newFiles = {
      ...sFiles,
      [docID]: {
        document_status: status,
        rejection_reason: isRejected ? reason : '',
        other_reason: isRejected && reason === 'Other' ? sFiles[docID]?.other_reason || '' : ''
      }
    }
    
    setSelectedFiles(prevState => ({
      ...prevState,
      [docID]: {
        ...prevState[docID],
        document_status: status,
        rejection_reason: isRejected ? reason : '',
        other_reason: isRejected && reason === 'Other' ? prevState[docID]?.other_reason || '' : ''
      }
    }));
    
    const reviewedDocs = Object.keys(newFiles);    
    if (reviewedDocs.length > 0) {      
      const rejectedDocs = reviewedDocs.filter(docId => newFiles[docId].document_status === 'rejected');
      if (rejectedDocs.length > 0) {
        setShowCall(true);
      } else {
        setShowCall(false);
      }
    }


  };

  // Function to handle other reason change for a document
  const handleOtherReasonChange = (docID, otherReason) => {
    setSelectedFiles(prevState => ({
      ...prevState,
      [docID]: {
        ...prevState[docID],
        other_reason: otherReason,
      }
    }));
  };

  // Function to unlock the case
  const unlockCase = async () => {
    try {
      const caseId = cases[currentCaseIndex]?.case_ids[0];
      if (caseId) {
        await axios.post(`${axiosDomain}/api/unlock-case`, { case_id: caseId }, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
        });
      }
    } catch (err) {
      console.error("Error unlocking the case:", err);
      setError('Failed to unlock the case. Please try again later.');
    }
  };

  // Modal window confirm and cancel functions
  const handleModalConfirm = async () => {
    setShowModal(false);
    // User chooses to proceed without saving progress
    setSelectedFiles({});
    if (nextAction === 'next') {
      if (currentCaseIndex < cases.length - 1) {
        await unlockCase();
        setCurrentCaseIndex(currentCaseIndex + 1);
      } else {
        // Last case, handle completion
        setIsComplete(true);
      }
    } else if (nextAction === 'previous') {
      if (currentCaseIndex > 0) {
        await unlockCase();
        setCurrentCaseIndex(currentCaseIndex - 1);
      }
    }
  };

  // Modal window cancel function
  const handleModalCancel = () => {
    setShowModal(false);
    // Stay on current case
  };

  // Function to download document via API
  const downloadDocument = async (docId, fileName) => {    
    
      try {

        const response = await axios.get(`${axiosDomain}/api/download-document`, {
        params: { docId: docId },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` },
        responseType: 'blob'
      });
        
      const url = window.URL.createObjectURL(new Blob([response.data])); // Create URL for the blob data
      const link = document.createElement('a'); // Create an anchor element
      link.href = url;
      link.setAttribute('download', fileName); // Set the download attribute with the file name
      document.body.appendChild(link); // Append the link to the DOM
      link.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(link); // Clean up and remove the link after download
    } catch (error) {
      console.error('Error downloading document:', error);
    }


  };

  if (isCasesLoading) {
    return <div>Loading...</div>;
  }

  // If an error occurred during data fetching
  if (error) {
    return (
      <div className="pending-case-container">
        <div className="error-message">{error}</div>        
          <button onClick={() => {
                                  setError(null);
                          }}>
            Try Again
          </button>
      </div>
    );
  }

  // If all cases are complete
  if (isComplete) {
    return (
      <div className="pending-case-container">
        <h2>All cases have been processed.</h2>
        {/* You can add more actions here, like redirecting or showing a summary */}
      </div>
    );
  }

  // If no cases are found (for the pending list or after applying the filters)
  if (!cases || cases.length === 0) {
    return (
      <div className="pending-case-container">
        <div className="filter-section">
          <div>
            <label>
              Filter by Lender: 
              <select
                value={selectedLender}
                onChange={(e) => setSelectedLender(e.target.value)}
              >
                <option value="">All Lenders</option>
                {allLenders.map((lender) => (
                  <option key={lender} value={lender}>{lender}</option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <label>
              Filter by Case Reference: 
              <input 
                type="text" 
                placeholder="Search by Case Reference" 
                onChange={(e) => setSearchValue(e.target.value)} 
                value={searchValue}
              />
            </label>          
          </div>
          <div className="filter-button"> 
            <button onClick={applyFilters}>Apply Filters</button>
          </div>
        </div>
        <hr className="filter-divider" />
        <p>No cases found</p>
      </div>
    );
  }

  // If the current case is not loaded yet
  const currentCase = cases[currentCaseIndex];

  return (
    <div className="pending-case-container">
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>{modalMessage}</p>
            <div>
              {modalType !== 'allDiscarded' && (<button onClick={handleModalConfirm}>Yes</button>)}
              {modalType === 'allDiscarded' ? (<button onClick={handleModalCancel}>Ok</button>) : (<button onClick={handleModalCancel}>No</button>)}
            </div>
          </div>
        </div>
      )}
      <div className="filter-section">
        <div>
          <label>
            Filter by Lender:             
          </label>
          <select
              value={selectedLender}
              onChange={(e) => setSelectedLender(e.target.value)}
            >
              <option value="">All Lenders</option>
              {allLenders.map((lender) => (
                <option key={lender} value={lender}>{lender}</option>
              ))}
          </select>
        </div>
        <div>
          <label>
            Filter by Case Reference:             
          </label>          
          <input 
              type="text" 
              placeholder="Search by Case Reference" 
              onChange={(e) => setSearchValue(e.target.value)} 
              value={searchValue}
            />
        </div>
        <div className="filter-button"> 
          <button onClick={applyFilters}>Apply Filters</button>
        </div>
      </div>
      <hr className="filter-divider" />      

      <div className="title-section">
        <button onClick={handlePrevious} disabled={currentCaseIndex === 0}>Previous</button>
        <h2>Pending Cases ({currentCaseIndex + 1} of {cases.length})</h2>
        {lockError && (
          <button onClick={() => forceUnlockCase(currentCase.case_ids[0])}>Unlock Case</button>
        )}
        <button 
          onClick={handleNext} 
          disabled={cases.length === 0}
        > 
          {currentCaseIndex === cases.length - 1 ? 'Complete' : 'Next'}
        </button>
      </div>

      <div className="client-info">
        <p><strong>Case Reference(s): </strong>{currentCase.case_ids.join(', ')}</p>
        <p><strong>Client: </strong>{currentCase.first_name} {currentCase.last_name}</p>
        <p><strong>Email: </strong>{currentCase.email}</p>
        <p><strong>Documents Submitted On: </strong>{(new Date(currentCase.uploaded_at)).toLocaleDateString()}</p>
        {/* <p><strong>Documents Submitted On: </strong>{currentCase.uploaded_at}</p> */}
        <table className="case-info-table">                  
        <thead>
          <tr className="case-info-table-header">
            <th>CA Reference</th>
            <th>Lender</th>
            <th>LOA Required</th>
            <th>POI Required</th>
            <th>POA Required</th>
          </tr>
        </thead>
        <tbody>
          {currentCase.case_ids.map((caseId, idx) => (
            <tr className="case-info-table-row" key={`case-info-row-${idx}`}>
              <td>{caseId}</td>
              <td>{currentCase.lenders[idx]}</td>
              <td>{currentCase.LOA_req[idx] === 1 ? 'Yes' : 'No'}</td>
              <td>{currentCase.POI_req[idx] === 1 ? 'Yes' : 'No'}</td>
              <td>{currentCase.POA_req[idx] === 1 ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
        </table>            
          
        
        
        {/* <p><strong>Lender: </strong>{currentCase.lenders.join(', ')}</p>
        <p><strong>LOA Required: </strong>{currentCase.LOA_req === 1 ? 'Yes' : 'No'}</p>
        <p><strong>POA Required: </strong>{currentCase.POA_req === 1 ? 'Yes' : 'No'}</p>
        <p><strong>POI Required: </strong>{currentCase.POI_req === 1 ? 'Yes' : 'No'}</p> */}
        
      </div>
      
        

      {showCall && (
        <div className="call-section">
          <input
            type="checkbox"
            id="call-client"
            checked={toCall}
            onChange={() => setToCall(!toCall)}
          />
          <label className="tblabel" htmlFor="call-client">
            Call client before sending rejection email
          </label>
        </div>
      )}      
      
      {lockError ? (
        <p className="error-message">{lockError}</p>
      ) : (
        <div className="documents-section">
          {isDocumentsLoading ? (
            <p>Loading documents...</p>
          ) : (
            <>
            {fileContents && fileContents.length > 0 ? (
              fileContents.map((doc, idx) => (
                <React.Fragment key={`document-fragment-${idx}`}>
                  <div className="document-row">
                    <div className="document-embed">
                      {doc.fileContent ? (
                        doc.fileName.endsWith('.pdf') ? (
                          <PDFViewer pdfData={atob(doc.fileContent)} />
                          // <iframe 
                          //   title={`Frame-${doc.id}`} 
                          //   src={`data:application/pdf;base64,${doc.fileContent}`} 
                          //   width="100%" 
                          //   height="500px" 
                          // />
                        ) : doc.fileName.match(/\.(jpg|jpeg|png)$/) ? (
                          <img 
                            src={`data:image/${doc.fileName.split('.').pop()};base64,${doc.fileContent}`} 
                            alt={doc.documenttype} 
                            style={{ maxWidth: '100%', maxHeight: '500px' }}
                          />
                        ) : (
                          <p>Cannot display this document type.</p>
                        )
                      ) : (
                        <p>No content available.</p>
                      )}
                      {/* Always show download button */}
                      <button 
                        onClick={() => downloadDocument(doc.id, doc.fileName)}
                        className="download-button"
                      >
                        Download {doc.documenttype}
                      </button>
                    </div>
                    <div className="document-options">
                      <div className="document-info">
                        <p><strong>Document Type:</strong> {doc.fileType}</p>
                        <p><strong>Status:</strong> {doc.fileStatus}</p>
                      </div>
                      <div className="action-buttons">
                        <button 
                          className={`accept-button ${selectedFiles[doc.id]?.document_status === 'accepted' ? 'active' : ''}`}
                          onClick={() => handleStatusChange(doc.id, 'accepted')}
                        >
                          Accept
                        </button>
                        <button 
                          className={`reject-button ${selectedFiles[doc.id]?.document_status === 'rejected' ? 'active' : ''}`}
                          onClick={() => handleStatusChange(doc.id, 'rejected')}
                        >
                          Reject
                        </button>
                        
                        <button 
                          className={`discard-button ${selectedFiles[doc.id]?.document_status === 'discarded' ? 'active' : ''}`}
                          onClick={() => handleStatusChange(doc.id, 'discarded')}
                        >
                          Discard
                        </button>
                        
                      </div>
                      <div className="status-icon">
                        {selectedFiles[doc.id]?.document_status === 'accepted' && (
                          <span className="checkmark">&#x2714;</span> 
                        )}
                        {selectedFiles[doc.id]?.document_status === 'rejected' && (
                          <span className="cross">&#x2716;</span> 
                        )}
                        {selectedFiles[doc.id]?.document_status === 'discarded' && (
                          <span className="trash">&#x1F5D1;</span>
                        )}                        
                      </div>
                      <div className="rejection-reason">
                        <label>
                          Rejection Reason:
                          <select
                            disabled={selectedFiles[doc.id]?.document_status !== 'rejected'}
                            value={selectedFiles[doc.id]?.rejection_reason || ''}
                            onChange={(e) => handleStatusChange(doc.id, 'rejected', e.target.value)}
                          >
                            <option value="">Select a reason</option>
                            {rejectionReasons.map((reason) => (
                              <option key={reason.reason} value={reason.reason}>{reason.reason}</option>
                            ))}
                            <option value="Other">Other</option>
                          </select>
                        </label>
                        {selectedFiles[doc.id]?.rejection_reason === 'Other' && (
                          <div>
                            <label>
                              Other Reason:
                              <input
                                type="text"
                                value={selectedFiles[doc.id]?.other_reason || ''}
                                onChange={(e) => handleOtherReasonChange(doc.id, e.target.value)}
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>              
                  </div>
                  <hr className="document-divider" />
                </React.Fragment>
              ))
            ) : (
              <p>No documents available for this case.</p>
            )}
            <ProcessedDocuments caseId={currentCase.case_ids[0]} />
            </>
          )}
        </div>              
      )}
      <div className="bottom-nav-buttons">
        <button onClick={handlePrevious} disabled={currentCaseIndex === 0}>Previous</button>
        <span>Case {currentCaseIndex + 1} of {cases.length}</span>
        <button 
          onClick={handleNext} 
          disabled={cases.length === 0}
        >
          {currentCaseIndex === cases.length - 1 ? 'Complete' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default PendingCases;
