import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);  
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const [limitAccess, setLimitAccess] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('utltoken');
    const checkMaintenanceMode = async () => {
      try {
        const response = await axios.get(`${axiosDomain}/api/maintenance-mode`);
        console.log('Maintenance mode:', response.data.mMode);
        setMaintenanceMode(response.data.mMode);
      } catch (error) {
        console.error('Error checking maintenance mode:', error);
      }
    };

    const validateToken = async (token) => {
      try {
        const response = await axios.post(`${axiosDomain}/api/validate-login`,{}, {
          headers: { 'Authorization': `Bearer ${token}` }
        });        
        if (response.data.user.role === 'restricted') {
          setLimitAccess(true);
        } else {
          setLimitAccess(false);
        }

        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    }

    checkMaintenanceMode();
    if (token) {      
      validateToken(token);
    } else {
        console.log('No token found');
        setIsAuthenticated(false);
    }
  }, []);

  const login = (token) => {
    localStorage.setItem('utltoken', token);
    setIsAuthenticated(true);    
  };

  const logout = async () => {
    localStorage.removeItem('utltoken');
    const unlockCase = async () => {
      try {
          await axios.post(`${axiosDomain}/api/unlock-case`, {}, {
              headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
          });
      } catch (error) {
          console.error("Error unlocking the case:", error);
      }
    };
    await unlockCase();
    setIsAuthenticated(false);    
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, limitAccess, setLimitAccess, login, logout, maintenanceMode }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
