import React, { useState } from 'react';
import axios from 'axios';
import './ClientSearch.css';
import { useAuth } from '../../context/AuthContext';

const ClientSearch = () => {
    const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;    
    const [searchString, setSearchString] = useState('');
    const [clientDetails, setClientDetails] = useState(null);
    const [error, setError] = useState('');
    const [emailStatus, setEmailStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [customEmail, setCustomEmail] = useState('');

    const handleSearch = async () => {
        setError('');
        setClientDetails(null);
        setEmailStatus('');
        setCustomEmail('');        
        if (!searchString.trim()) {
            setError('Please enter a search value.');
            return;
        }
        setLoading(true);
        try {
            const response = await axios.get(`${axiosDomain}/api/client-details`, {
                params: { searchString },
                headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
            });
            setClientDetails(response.data.clientDetails);
        } catch (err) {
            if (err.response && err.response.status === 404) {
                setError("Unfortunately, no matches found for the searched string. If you've tried using the CA Reference as well as the email address, it's possible that the client's details are not in the system.");
            } else {
                setError('An error occurred while fetching client details. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSendEmail = async () => {
        if (!clientDetails || !clientDetails.email) {
            setEmailStatus('No client email available to send.');
            return;
        }
        setEmailLoading(true);
        setEmailStatus('');
        try {
            const response = await axios.get(`${axiosDomain}/api/send-single-email`, {
                params: { 
                    email: clientDetails.email,
                    customEmail: customEmail                
                },
                headers: { 'Authorization': `Bearer ${localStorage.getItem('utltoken')}` }
            });
            const { message, emailResult, smsResult } = response.data;
            let statusMessage = '';
            if (message) {
                statusMessage += `${message}\n`;
            }
            if (emailResult) {
                statusMessage += `Email Status: ${emailResult}\n`;
            }
            if (smsResult) {
                statusMessage += `SMS Status: ${smsResult}`;
            }
            setEmailStatus(statusMessage);
        } catch (err) {
            setEmailStatus('An error occurred while sending the email. Please try again later.');
        } finally {
            setEmailLoading(false);
        }
    };

    return (
        <div className="search-container">
            <div className="search-form">
                <div className="search-header">
                    <h2>Client Search</h2>
                </div>
                <div className="form-group-search">
                    <label htmlFor="searchString">Search by Email or Case ID</label>
                    <input
                        type="text"
                        id="searchString"
                        name="searchString"
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        placeholder="Enter email or case ID"
                    />
                </div>
                <button className="search-button" onClick={handleSearch} disabled={loading}>
                    {loading ? 'Searching...' : 'Search'}
                </button>
                {error && <p className="error-message">{error}</p>}
                {clientDetails && (
                    <div className="client-details">
                        <h3>Client Details</h3>
                        <p><strong>Email:</strong> {clientDetails.email}</p>
                        <p><strong>First Name:</strong> {clientDetails.first_name}</p>
                        <p><strong>Last Name:</strong> {clientDetails.last_name}</p>
                        <p><strong>Access Link: </strong><a href={clientDetails.token}>{clientDetails.token}</a></p>
                        <p><strong>Password / PIN: </strong>{clientDetails.password}</p>
                        <p><strong>Telephone:</strong> {clientDetails.telephone}</p>
                        <p><strong>Mobile:</strong> {clientDetails.mobile}</p>
                        <p><strong>Case ID(s):</strong> {clientDetails.cases}</p>
                        <input type="text" value={customEmail} onChange={(e) => setCustomEmail(e.target.value)} placeholder="Enter custom email" />

                        <button className="email-button" onClick={handleSendEmail} disabled={emailLoading}>
                            {emailLoading ? 'Sending Email...' : 'Send Email'}
                        </button>
                        {emailStatus && <p className="status-message">{emailStatus}</p>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClientSearch;
